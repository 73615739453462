.pages {
    // max-width: 1600px;
    margin: 0 auto;

    @media only screen and (min-width: $screen-w--s) {

    }

    @media only screen and (min-width: $screen-w--l) {

    }

    & > {
        @for $i from 1 to 9 {
            section:nth-child(#{$i}) {
                // background-color: rgba(0, 0, 0, $i / 20);
            }
        }
    }

    & > section {
        position: relative;
        // height: auto;

        /* @media only screen and (min-width: $screen-w--mx) {
            height: 750px;
        } */
    }
}

.calculator {
    background-color: $color-grey--l3;
}

.trade {
    background-color: $color-blue--v1;
}

.mass-media {
    background-color: $color-grey--v2;
}

.container {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 17px;

    @media only screen and (min-width: $screen-w--s) {
        padding: 0 40px 0 42px;
    }

    @media only screen and (min-width: $screen-w--l) {
        max-width: 900px;
        padding: 0;
    }
}

.container--m {
    @media only screen and (min-width: $screen-w--l) {
        max-width: 1000px;
    }
}

.container--l {
    @media only screen and (min-width: $screen-w--l) {
        max-width: 1280px;
    }
}
