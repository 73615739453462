.video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background: url('../img/content/i-promo-4.jpg') center center;
    background-size: cover;
    &.ipoteka-page {
        background: url('../img/content/video-img-sm.jpg') center center;
        background-size: cover;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 43, 53, 0.4);
        z-index: 10;
    }

    &__container {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;

        @media only screen and (min-width: $screen-w--s) {
            display: block;
        }
    }
}
