.preparation {
    position: relative;
    padding-top: 50px;
    overflow: hidden;

    @media only screen and (min-width: $screen-w--s) {
        padding-top: 100px;
    }

    @media only screen and (min-width: $screen-w--mx) {
        // height: auto !important;
    }

    @media only screen and (min-width: $screen-w--l) {
        height: 750px;
    }

    &__block {
        z-index: 100;
    }

    &__illustration {
        position: relative;
        margin-bottom: 20px;
        background: url('../img/content/preparation-back-1.jpg') no-repeat 50% 55% /cover;

        @media only screen and (min-width: $screen-w--l) {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin-bottom: 0;
        }
    }

    &__back {
        vertical-align: top;
        opacity: 0;
        visibility: hidden;

        @media only screen and (min-width: $screen-w--l) {
            display: none;
        }
    }

    &__step-name {
        text-transform: uppercase;
        letter-spacing: 1.7px;
        font-family: $font-family--varBold;
        font-size: 11px;
        opacity: 0.7;
        margin-bottom: 6px;

        @media only screen and (min-width: $screen-w--s) {
            letter-spacing: 2px;
            font-size: 13px;
            margin-bottom: 4px;
        }
    }

    &__head {
        color: $color-grey--v4;
    }

    &__info {
        margin-bottom: 18px;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            margin-bottom: 28px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            width: 540px;
        }

        a {
            color: $color-blue--v2;
        }
    }

    &__button {
        margin-top: 20px;

        @media only screen and (min-width: $screen-w--s) {
            max-width: 215px;
            margin-bottom: 40px;
        }
    }

    &__pointers {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        list-style: none;
        z-index: 110;

        @media only screen and (min-width: $screen-w--l) {
            display: block;
        }

        li {
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;

            &:hover {
                .preparation__pointer {
                    transform: scale(1.2);
                }
            }
        }
    }

    &__pointer {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 100%;
        transition: transform 0.15s ease-in-out;

        /* &:hover {
            transform: scale(1.2);
        }*/

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 14px;
            height: 14px;
            border-radius: 100%;
            border: 2px solid $color-white;
            background-color: $color-blue--v2;
        }
    }

    &__pointer-text {
        position: absolute;
        bottom: 100%;
        left: 20px;
        color: $color-blue--v2;
        line-height: $line-height--sl;
        white-space: nowrap;
    }
}
