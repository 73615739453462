.qa {

    padding: 50px 0;    
    background-color: $color-grey--v1;
    
    @media only screen and (min-width: $screen-w--s) {
        padding: 100px 0;    
    }

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(51, 51, 51, 0.85);
    }

    &__block {
        width: 100%;
        &--right {
            @media only screen and (min-width: $screen-w--m) {
                width: 640px;
            }
        }
        &--left {
            @media only screen and (min-width: $screen-w--m) {
                width: 250px;
            }
        }
    }

    &__title {
        margin-bottom: 30px;
        font-size: 25px;
        @media only screen and (min-width: $screen-w--s) {
            font-size: 40px;
        }
        @media only screen and (min-width: $screen-w--m) {
            font-size: 45px;
        }
    }
    
    &__tab-item {
        margin-bottom: 5px;
        margin-right: 5px;
        &.active .qa__tab-link {
            background-color: #606060;
            color: #fff
        }
    }

    &__tab-links {
        display: none;
        margin-bottom: 10px;
        flex-wrap: wrap;
        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 15px;
        }
    }

    &__tab-link { 
        background: transparent;   
        border: 0;    
        outline: 0;
        border-radius: 5px;
        color: #7a7a7a;
        cursor: pointer;
        font-size: 14px;
        padding: 4px 9px;
        text-align: left;
        transition: color 150ms, background 150ms;

        &:hover {
            color: #4c4c4c;
        }
        &:last-child {
            margin-right: 0;
        }

        @media only screen and (min-width: $screen-w--s) {
            font-size: 19px;
            padding: 5px 15px;            
        }

        @media only screen and (min-width: $screen-w--m) {
            font-size: 16px;
            padding: 5px 12px;
        }
        
    }

    &__collapse-head{
        font-size: 14px;
        padding: 15px 24px;
        @media only screen and (min-width: $screen-w--s) {
            font-size: 20px;
        }
    }

    &__head-icon {
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 6px;
        font-weight: bold;
        transition: 0.3s;
    }

    &__collapse-block {
        border-bottom: 1px solid #e1e1e4;

        &.active .qa__head-icon {
            transform: rotate(-90deg);
            transition: 0.3s;
        }
    }
    &__collapse-content {
        padding: 0 24px 20px;
        font-size: 14px;
        line-height: 19px;
        @media only screen and (min-width: $screen-w--s) {
            font-size: 18px;
            line-height: 25px;
        }
        @media only screen and (min-width: $screen-w--m) {
            font-size: 16px;
            line-height: 25px;
        }
    }

}
