.purpose-list {
  @media only screen and (min-width: $screen-w--m) {
    padding: 85px 0;
  }

  &__container {

    @media only screen and (max-width: $screen-w--m - 1) {
      padding: 0;
    }
  }
}

.purpose {
  position: relative;
  overflow: hidden;
  min-height: 385px;

  // &:hover {
  //     .purpose__img {
  //         transform: scale(1.05)
  //     }
  // }

  &--light {
    color: $color-white;

    .purpose__head {
      color: $color-white;
    }
  }

  &--dark {
    .purpose-info-item__title {
      color: #1e1f21;
    }

    .purpose__desc {
      color: rgba(#1e1f21, 0.5);
    }

    .btn--trans {
      color: #7d5ce4;
    }
  }

  &--1 {
    background-color: #45aaf3;


    .purpose__img {
      @media only screen and (min-width: $screen-w--s) {
        background-image: url('../img/content/ipoteka-img.png');
      }
    }
  }

  &--2 {
    background-color: #04518b;

    .purpose__img {
      background-repeat: no-repeat;
      background-position: right center;

      @media only screen and (min-width: $screen-w--s) {
        background-image: url('../img/content/refin-img.png');
      }

      @media only screen and (min-width: $screen-w--mx) {
        right: 0;
      }
    }
  }

  &--3 {
    background-color: #c5d6ea;

    .purpose__img {
      right: 0;
      @media only screen and (min-width: $screen-w--s) {
        background-image: url('../img/content/realty-img.png');
      }
      @media only screen and (min-width: $screen-w--m) {

      }
    }
  }

  &--4 {
    background-color: #e4ecef;

    .purpose__img {
      right: 0;
      @media only screen and (min-width: $screen-w--s) {
        background-image: url('../img/content/family-img.jpg');
      }
      @media only screen and (min-width: $screen-w--m) {

      }
    }

    .purpose__inner {
      @media only screen and (min-width: $screen-w--m) {
        color: #fff;

        .purpose__head {
          color: inherit;
        }

        .purpose__desc {
          color: inherit;
          opacity: 0.7;
          max-width: 480px;
        }

        .purpose-info-item__title {
          color: inherit;
          opacity: 0.7;
        }

        .purpose__action .btn {
          color: inherit;
        }
      }
    }
  }

  &--5 {
    background-color: #c5d6ea;

    .purpose__img {
      right: 0;
      @media only screen and (min-width: $screen-w--s) {
        background-image: url('../img/content/newflats-img.png');
      }
      @media only screen and (min-width: $screen-w--m) {

      }
    }

    .purpose__inner {
      @media only screen and (min-width: $screen-w--m) {
        color: #fff;

        .purpose-info-item:not(:last-of-type) {
          max-width: 160px;
        }

        .purpose__desc {
          color: #000000;
          opacity: 0.5;
          max-width: 480px;
        }

        .purpose-info-item__title {
          color: rgba(0, 0, 0, 0.50);
        }

        .purpose-info-item__value {
          color: #333333;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-w--m) {
    border-radius: 8px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    visibility: hidden;
    background-size: cover;

    @media only screen and (min-width: $screen-w--m) {
      visibility: visible;
    }
  }


  &__inner {
    position: relative;
    padding: 50px 17px;
    max-width: 740px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $screen-w--xs) {
      padding: 50px 17px 94px;
    }

    @media only screen and (min-width: $screen-w--s) {
      padding: 50px 40px 94px;
    }

    @media only screen and (min-width: $screen-w--m) {
      padding: 40px;
    }
  }

  &__head {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 10px;
    color: $color-grey--v4;

    // @media only screen and (min-width: $screen-w--s) {
    //     font-size: 25px;
    //     line-height: 27px;
    // }
  }

  &__desc {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.5);

    @media only screen and (min-width: $screen-w--s) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    // @media only screen and (min-width: $screen-w--s) {
    //     font-size: 20px;
    // }

    &--grey {
      color: #6a6a6a;
    }
  }

  &__action {
    @media only screen and (min-width: $screen-w--xs) {
      display: flex;
      margin-top: auto;
    }

    .btn {
      margin: 0 0 15px 0;

      @media only screen and (max-width: $screen-w--xs) {
        margin-bottom: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media only screen and (min-width: $screen-w--xs) {
        width: auto;
        margin: 0 15px 0 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.purpose-info {
  margin-bottom: 15px;
  flex-direction: column;

  @media only screen and (min-width: $screen-w--s) {
    display: flex;
    flex-direction: row;
  }

  &-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (min-width: $screen-w--s) {
      width: 33.33333%;
    }

    &--row {
      display: flex;
      align-items: center;

      .purpose-info-item__img {
        width: 36px;
        flex-shrink: 0;
        margin-right: 10px;
      }
    }

    &__title {
      font-size: 12px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.7);

      @media only screen and (min-width: $screen-w--xs) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__value {
      font-size: 25px;
      line-height: 31px;
      font-family: $font-family--varRegular;

      @media only screen and (min-width: $screen-w--xs) {
        font-size: 36px;
        line-height: 43px;
      }
    }
  }
}

.request-form {
  margin: 70px 0;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.10);
  box-sizing: border-box;

  @media only screen and (min-width: $screen-w--s) {
    max-width: 650px;

    .popup-request {
      padding: 50px 100px 50px 50px;
      width: 640px;
    }
  }
}
