.tab {
    &__links {
        display: flex;
        list-style: none;
    }

    &__item {
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-child {
            margin-right: 0;
        }        
    }

    &__link {
        padding: 5px 12px;
        border-radius: 5px;
        font-size: 16px;
        line-height: 20px;
        font-family: $font-family--varRegular;
        
    }

    &__content {

        &.hide {
            display: none
        }
    }
}