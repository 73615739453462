.purpose-links {
    margin: 60px -17px 0;

    @media only screen and (min-width: $screen-w--s) {
        margin-top: 80px;
        display: flex;
    }

    @media only screen and (min-width: $screen-w--l) {
        margin: 80px -60px 0;
    }
}

.purpose-link {
    position: relative;
    height: 132px;
    padding: 25px 0 0;
    display: block;
    overflow: hidden;
    color: $color-grey--v4;
    cursor: pointer;

    &:hover {
        &::before {
            transform: scale(1.05)
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-position: right center;
        transform-origin: center;
        background-repeat: no-repeat;
        transition: transform 1s;

        @media only screen and (min-width: $screen-w--m) {
            background-position: right bottom;
        }
    }

    @media only screen and (min-width: $screen-w--s) {
        width: 50%;
        height: 350px;
        padding: 40px 30px 0;
        background-size: auto;
    }

    &--1 {
        background-color: #7D5CE4;

        &::before {
            background-size: cover;
            background-image: url('../img/content/ipoteka-img.png');
        }
    }

    &--2 {
        background-color: #ff83a2;
        color: $color-white;

        &::before {
            background-size: cover;
            background-position: right bottom;
            background-image: url('../img/content/refin-img.png');
        }
    }

    &--3 {
        background-color: #f6f5fd;

        &::before {
            background-size: cover;
            background-image: url('../img/content/realty-img.png');
        }
    }

    &--4 {
        background-color: #e4ecef;
        color: $color-white;

        &::before {
            background-size: cover;
            background-image: url('../img/content/family-img.jpg');
        }
    }

    &--light {
        color: $color-white;

        .purpose-link__title {
            color: $color-white;
        }
    }


    &__title {
        font-size: 16px;
        color: $color-grey--v4;
        font-family: $font-family--varBold;
        position: relative;
        padding: 0 20px;
        line-height: 1.2;

        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 10px;
            font-size: 25px;
            padding: 0;
            max-width: 300px;
        }

        &--white {
            color: $color-white;
        }
    }

    &__desc {
        display: none;
        margin: 0;
        position: relative;
        line-height: 1.38;

        @media only screen and (min-width: $screen-w--s) {
            display: block;
            max-width: 300px;
            opacity: 0.6;
        }
    }
}
