.currency {
    font-family: $font-family--currency;
    margin-left: 0.2em;
    font-size: 0.9em;
    font-weight: normal !important;

    &--middle {
      vertical-align: middle;
    }
}
