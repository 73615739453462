.popover-hover {
    $container: &;
    display: none;

    %popover-hover-icon {
        width: 27px;
        height: 27px;
        position: relative;
        cursor: pointer;
    }

    &__icon-blue{
        @extend %popover-hover-icon;
        background-image: url('../img/icons/icon-quest.svg');
    }
    &__icon-white{
        @extend %popover-hover-icon;
        background-image: url('../img/icons/icon-quest-white.svg');
    }

    &__content {
        position: absolute;
        bottom: -15px;
        left: 99999px;
        width: 387px;
        padding: 15px;
        font-family: $font-family--var1;
        font-weight: normal;
        font-size: $font-size--m;
        line-height: $line-height--xl;
        color: $font-color;
        background: $color-white;
        transform: translateY(100%);
        opacity: 0;
        height: 0;
        transition: opacity 0.3s;

        &:after {
            content: '';
            position: absolute;
            top: -5px;
            left: 33px;
            width: 0;
            height: 0;
            border-bottom: solid 10px $color-white;
            border-left: solid 10px transparent;
            border-right: solid 10px transparent;
        }
    }

    &:hover {
        #{$container}__content {
            opacity: 1;
            height: auto;
            left: -30px;
        }
    }

    @media only screen and (min-width: $screen-w--m) {
        display: inline-block;
    }


    &-mortgage{
        padding-top: 2px;
        #{$container}__content {
            width: 340px;
            padding: 10px 18px 10px 10px;
        }
    }
}
