.news-container {
    width: 100%;
    padding: 0 15px;
    @media only screen and (min-width: $screen-w--mx) {
        width: 1000px;
        margin: 0 auto;
    }
}

.section {
    &[data-header="news"] {
        padding: 0px;
        min-height: calc(100vh - 300px);

        .news-block-list {
            margin-top: 20px;
            margin-bottom: 30px;
            height: 100%;

            .pagination {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                li {
                    list-style-type: none;
                    margin-right: 20px;

                    a {
                        color: $color-grey--v4;
                        font-size: $font-size--l;
                        display: block;

                        &:hover {
                            color: #016eff;
                        }
                    }

                    &.active {
                        a {
                            color: #016eff;
                        }
                    }
                }
            }
        }

        .topical-news {
            display: flex;
            margin-bottom: 40px;
            flex-flow: column nowrap;
            color: $color-grey--v4;
            @media only screen and (min-width: $screen-w--mx) {
                flex-flow: row nowrap;
            }
            &:hover {
                .topical-news__link {
                    color: #016eff;
                }
            }

            &__image {
                width: 100%;
                height: 233px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;

                @media only screen and (min-width: $screen-w--s) {
                    height: 290px;
                }

                @media only screen and (min-width: $screen-w--mx) {
                    width: 430px;
                    height: 290px;
                    margin-right: 50px;
                    flex: 1 0 auto;
                }
            }
            &__link {
                color: $color-grey--v4;
                font-size: 26px;
                font-family: $font-family--var2;
            }
            &__content {
                display: flex;
                flex-flow: column nowrap;
                margin: 15px 0;
                @media only screen and (min-width: $screen-w--mx) {
                    border-bottom: solid 1px $color-grey--v5;
                    margin: 0;
                }
            }
            &__spacer {
                flex: 1 0 auto;
            }
            &__date {
                margin: 5px 0 10px 0;
                color: #949494;
                font-size: $font-size--sl;
            }
        }

        .news-list {
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            flex-flow: column nowrap;
            @media only screen and (min-width: $screen-w--mx) {
                flex-flow: row nowrap;
            }

            &__topical {
                margin-bottom: 40px;
                padding-bottom: 15px;
                border-bottom: solid 1px $color-grey--v5;
                color: $color-grey--v4;

                &:hover {
                    .topical-news__link{
                        color: #016eff;
                    }
                }

                @media only screen and (min-width: $screen-w--mx) {
                    margin-right: 50px;
                    padding-bottom: 0;
                    border-bottom: none;
                    max-width: 710px;
                }
            }
            &__image {
                width: 100%;
                height: 233px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                flex: 1 0 auto;
                margin-bottom: 20px;

                @media only screen and (min-width: $screen-w--s) {
                    height: 290px;
                }

                @media only screen and (min-width: $screen-w--mx) {
                    width: 710px;
                    height: 400px;
                }
            }

            &__content {
                margin-bottom: 40px;
                @media only screen and (min-width: $screen-w--mx) {
                    margin-bottom: 0;
                }
            }

            &__item {
                margin-bottom: 15px;
                border-bottom: solid 1px $color-grey--v5;
                & a {
                    font-family: $font-family--varBold;
                }
                &:hover {
                    .news-list__item-link {
                        color: #016eff;
                    }
                }
            }
            &__item-link {
                color: $color-grey--v4;
                font-size: $font-size--m;
                font-family: $font-family--varBold;
            }
        }

        .news-block {
            margin-bottom: 20px;
            border-bottom: solid 2px #1e1f21;

            @media only screen and (max-width: $screen-w--m) {
                position: relative;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .post-page {
            &.gray {
                background: #f5f5f5;
            }

            .post-page-section {
                padding-top: 30px;
                clear: left;
                background: white;

                @media only screen and (max-width: $screen-w--mx) {
                    margin: 20px 0 30px 0;
                }

                &__image {
                    width: 100%;
                    height: 230px;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                    flex: 1 0 auto;
                    margin-bottom: 20px;
                    @media only screen and (min-width: $screen-w--s) {
                        height: 400px;
                    }
                    @media only screen and (min-width: $screen-w--mx) {
                        height: 580px;
                    }
                }

                &__header {
                    color: #949494;
                    font-size: 16px;

                    @media only screen and (max-width: $screen-w--mx) {
                        font-size: 13px;
                    }

                    &-title {
                        margin: 20px 50px;

                        @media only screen and (max-width: $screen-w--mx) {
                            margin: 0;
                        }

                        h2 {
                            color: $color-grey--v4;
                            font-size: 52px;
                            @media only screen and (max-width: $screen-w--m) {
                                color: $color-grey--v4;
                                font-size: 24px;
                            }
                        }
                    }
                    &-link {
                        margin-bottom: 10px;
                        font-size: 14px;

                        a {
                            color: #0055ff;
                            margin-right: 20px;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                        }
                    }
                }
                .news-content-source {
                    margin: 40px 10% 0px 10%;
                    font-size: 16px;
                    a {
                        font-size: 16px;
                        color: #016eff;
                    }
                }

                .news-content {
                    margin: 20px 50px 0;

                    @media only screen and (max-width: $screen-w--m) {
                        margin: 0;
                    }

                    h2 {
                        margin-top: 40px;
                        color: #000000;
                        font-size: 28px;
                        a {
                            margin: 0px 25%;
                        }
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: left;
                        color: #1e1f21;
                        a {
                            color: #0055ff;
                            text-align: center;
                        }
                        img {
                            height: auto;
                            margin: 0;
                            @media only screen and (max-width: $screen-w--m) {
                                margin-bottom: 10px;
                            }
                            &.alignright {
                                margin-left: 15px;
                                float: right;
                            }
                            &.alignleft {
                                margin-right: 15px;
                                float: left;
                            }
                            &.aligncenter {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }

                    blockquote {
                        p {
                            font-size: 24px;
                            font-style: italic;
                            line-height: 1.42;
                            text-align: center;
                            color: #1e1f21;
                        }
                    }

                    ul,
                    ol {
                        margin: 20px 10%;
                        line-height: 1.5;
                    }
                }
                .ya-share2 {
                    ul {
                        list-style-type: none;
                        margin: 30px 50px 60px;
                        display: flex;
                        justify-content: center;
                        @media only screen and (max-width: $screen-w--m) {
                            margin: 30px 0 60px;
                        }
                        .ya-share2__item {
                            width: 100%;
                            a {
                                width: 100%;
                                .ya-share2__badge {
                                    width: 100%;
                                    height: 40px;
                                    text-align: center;
                                    padding: 3px 0px;
                                    .ya-share2__icon {
                                        vertical-align: middle;
                                        height: 35px;
                                        width: 35px;
                                    }
                                }
                            }
                        }
                    }
                }

                .news-block-list {
                    border-top: solid 2px #1e1f21;
                    padding-top: 20px;

                    .title-block {
                        padding-bottom: 40px;

                        @media only screen and (max-width: $screen-w--m) {
                            font-size: 16px;
                            padding-bottom: 20px;
                            a {
                                font-size: 16px;
                                bottom: 0;
                                left: 0;
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
