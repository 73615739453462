//---------------------------------
// Base
//---------------------------------

// Variables, mixins, extends, reset etc.

@import "variables/colors";
@import "variables/base";
@import "mixins/clearfix.scss";
@import "mixins/fonts.scss";
@import "mixins/sprites.scss";
@import "/app/tmp/sprites/sp-basic.scss";
@import "/app/tmp/sprites/sp-icons.scss";
@import "vendor/normalize";
@import "vendor/slider";
//---------------------------------
// Shared.
//---------------------------------

// Fonts, grid, icons, buttons, form elements etc.

@import "shared/fonts";
@import "shared/reboot";
@import "shared/typography";
@import "shared/icons";
@import "shared/buttons";
@import "shared/tab";
@import "shared/form";
@import "shared/form-controls";
@import "shared/popup";
@import "shared/footer";

//---------------------------------
// Components.
//---------------------------------

// Header, footer, navigation, news etc.

@import "components/agency.scss";
@import "components/articles.scss";
@import "components/badge.scss";
@import "components/blocks-form.scss";
@import "components/blured-items.scss";
@import "components/buy-step.scss";
@import "components/calculator.scss";
@import "components/carousel.scss";
@import "components/chat-animations.scss";
@import "components/chat-block.scss";
@import "components/collapse.scss";
@import "components/condition.scss";
@import "components/coupons.scss";
@import "components/currency.scss";
@import "components/evaluation.scss";
@import "components/feat.scss";
@import "components/foundation.scss";
@import "components/hamburger.scss";
@import "components/header.scss";
@import "components/ipoteka-fs.scss";
@import "components/items-row.scss";
@import "components/loader.scss";
@import "components/nav.scss";
@import "components/news-page.scss";
@import "components/odometer.scss";
@import "components/partnerModal.scss";
@import "components/partners.scss";
@import "components/popover-hover.scss";
@import "components/popups.scss";
@import "components/preparation.scss";
@import "components/purpose-links.scss";
@import "components/purpose.scss";
@import "components/qa.scss";
@import "components/range-slider.scss";
@import "components/range.scss";
@import "components/refinancing-modal.scss";
@import "components/section.scss";
@import "components/trade.scss";
@import "components/video.scss";
@import "components/yellowShutter.scss";
 // Import all components
@import "components/Partner/X-Holding.scss";
@import "components/Partner/young-family.scss";

#altchat .altchat-messenger{
    z-index: 999999 !important;
}
.beesender-widget-button-icon-container{
    width: 100%;
}

//---------------------------------
// Override.
//---------------------------------


