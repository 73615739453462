.footer {
  padding: 18px;
  text-align: left;

  &__spaceBetween {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @media only screen and (min-width: $screen-w--mx) {
      text-align: left;
    }
  }

  &__outside {
    display: none;
  }

  &__skolkovo {
    margin-left: 30px;
  }

  @media only screen and (max-width: $screen-w--xs) {
    font-size: 14px;
  }

  @media only screen and (min-width: $screen-w--s) {
    padding: 65px 20px 57px;
    font-size: $font-size--l;
    line-height: $line-height--sl;
  }

  @media only screen and (min-width: $screen-w--mx) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 35px;
    font-size: $font-size--sl;
    line-height: normal;
  }

  &__copyright {
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: $screen-w--s) {
      margin-bottom: 28px;
    }

    @media only screen and (min-width: $screen-w--mx) {
      margin-bottom: 0;
      flex-direction: column;
      width: 40%;
    }

    @media only screen and (max-width: $screen-w--mx) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .footer__inside {
        display: none;
      }

      .footer__outside {
        display: block;
      }
    }
  }

  &__info {
    list-style: none;
    vertical-align: middle;

    @media only screen and (min-width: $screen-w--mx) {
      text-align: right;
    }

    li {
      margin-bottom: 17px;

      &:last-child {
        margin-bottom: 0;
      }

      @media only screen and (min-width: $screen-w--s) {
        margin-bottom: 28px;
      }

      @media only screen and (min-width: $screen-w--mx) {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: 0;
      }
    }

    a {
      color: $color-blue--v2;
      transition: color 0.15s ease-in-out;

      &:hover {
        color: $color-blue--v4;
      }

      &.icon-facebook-xs {
        font-size: 17px;
        color: $color-grey--v4;

        @media only screen and (min-width: $screen-w--s) {
          font-size: 27px;
        }

        @media only screen and (min-width: $screen-w--mx) {
          font-size: 22px;
        }
      }
    }
  }

  &[data-header='news'] {
    border-top: solid 2px #1e1f21;
  }
}
