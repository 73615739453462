.hamburger {
    position: absolute;
    right: 17px;
    top: 16px;
    cursor: pointer;
    height: 15px;
    width: 19px;

    @media only screen and (min-width: $screen-w--s) {
        right: 26px;
        top: 26px;
        height: 20px;
        width: 25px;
    }

    @media only screen and (min-width: $screen-w--m) {
        display: none;
    }

    &__icons {
        position: absolute;
        left: 0;
        top: 6px;
        display: block;
        height: 3px;
        width: 19px;
        background: $color-black;

        @media only screen and (min-width: $screen-w--s) {
            width: 25px;
            height: 4px;
            top: 8px;
        }


        &::before,
        &::after {
            position: absolute;
            display: block;
            left: 0;
            width: 19px;
            height: 3px;
            background: $color-black;
            content: "";

            @media only screen and (min-width: $screen-w--s) {
                width: 25px;
                height: 4px;
            }
        }

        &::before {
            top: -7px;

            @media only screen and (min-width: $screen-w--s) {
                top: -8px;
            }
        }

        &::after {
            bottom: -7px;

            @media only screen and (min-width: $screen-w--s) {
                bottom: -8px;
            }
        }

        &[data-change-color="light"] {
            background-color: $color-white;

            &::before,
            &::after {
                background-color: $color-white;
            }
        }
    }
}
