// Color types
//---------------------------------

$color-default: $color-grey !default;
$color-primary: $color-purple !default;
$color-success: $color-green !default;
$color-info: $color-blue !default;
$color-important: $color-yellow !default;
$color-warning: $color-orange !default;
$color-danger: $color-red !default;
$color-disabled: $color-grey !default;

// Spacer
//---------------------------------

$spacer: 10px !default;
$spacer--x2: $spacer * 2 !default;

// Screen
//---------------------------------

$screen-w--xxs: 365px !default; // Mobile Version
$screen-w--xs: 480px !default; // Mobile Version
$screen-w--s: 768px !default; // Tablet Version
$screen-w--m: 960px !default; // Tablet Version
$screen-w--mx: 1000px !default; // Desktop Version
$screen-w--l: 1200px !default; // Desktop Version
$screen-w--xl: 1400px !default; // Desktop Version
$screen-w--xxl: 1600px !default; // Desktop Version

// Typography
//---------------------------------

$font-family--sansSerif: Arial, sans-serif !default;
$font-family--serif: Georgia, 'Times New Roman', Times, serif !default;
$font-family--monospace: Monaco, Menlo, Consolas, 'Courier New', monospace !default;
$font-family--currency: 'HelveticaNeueDeskInterface', monospace !default;

$font-family--var1: 'MuseoSansCyrl_500' !default;
$font-family--var2: 'MuseoSansCyrl_900' !default;

$font-family--varRegular: $font-family--var1 !default;
$font-family--varBold: $font-family--var2 !default;

$font-family--base: $font-family--sansSerif !default;
$font-family--alt: $font-family--serif !default;

$font-size--s: 12px !default;
$font-size--sm: 13px !default;
$font-size--sl: 14px !default;
$font-size--m: 16px !default;
$font-size--ml: 18px !default;
$font-size--l: 20px !default;
$font-size--lx: 30px !default;
$font-size--xl: 32px !default;
$font-size--xxl: 40px !default;

$line-height--s: 1 !default;
$line-height--sm: 1.15 !default;
$line-height--sl: 1.29 !default;
$line-height--m: 1.38 !default;
$line-height--l: 1.4 !default;
$line-height--xl: 1.5 !default;
$line-height--xxl: 1.8 !default;

$font-color: $color-black--v2 !default;
$link-color: $color-white !default;
$heading-color: $color-black--v2 !default;

// Background
//---------------------------------

$bg-main: $color-white !default;

// Z-index
//---------------------------------

$zindex-fixed: 1000 !default;
$zindex-overlay: 5000 !default;
$zindex-modal: 7000 !default;
