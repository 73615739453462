.btn {
  display: block;
  width: 100%;
  position: relative;
  padding: 13px 15px;
  border: none;
  border-radius: 3px;
  outline: none;
  background: $color-purple--v1;
  font-family: $font-family--varRegular;
  font-size: $font-size--m;
  line-height: $line-height--sm;
  color: $color-white;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 14px 24px 0 rgba(125, 92, 228, 0.21);

  @media only screen and (min-width: $screen-w--s) {
    display: inline-block;
    padding: 15px 25px;
    font-size: $font-size--ml;
  }

  &:hover {
    background-color: $color-deep-purple;
  }

  &:disabled,
  &.is-disabled {
    &,
    &:hover,
    &:active {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.btn--white {
  background-color: $color-white;
  color: $color-blue--v5;

  &:hover {
    background-color: #e8e8e8;
  }
}

.btn--gray {
  background-color: $color-grey--v13;
  color: $color-white;

  &:hover {
    background-color: #e8e8e8;
  }
}

.btn--trans {
  background-color: rgba(255, 255, 255, 0.2);
  color: $color-white;
  box-shadow: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.btn--trans--black {
  background-color: rgba(255, 255, 255, 0.2);
  color: $color-black;
  box-shadow: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.btn--grey {
  background-color: rgba(13, 26, 69, 0.3);
  color: #595959;

  &:hover {
    background-color: rgba(13, 26, 69, 0.25);
  }
}
.btn--indigo {
  background-color: $color-indigo--v1;
  color: #595959;
  min-width: 100px;
  box-shadow: none;

  @media only screen and (max-width: $screen-w--xs) {
    min-width: 65px;
  }

  &:hover {
    background-color: #f4f4f8;
  }
}

.btn--dark-blue {
  background-color: #3c01ff;

  &:hover {
    background-color: #2f38ff;
  }
}

// Sizes
//---------------------------------

.btn {
  &.size-s {
    padding: 7px 12px;
    font-size: $font-size--s;
    line-height: $line-height--s;
  }

  &.size-sm {
    padding: 7px 12px;
    font-size: $font-size--sl;
    line-height: $line-height--s;
  }

  &.size-l {
    padding: 9px 20px;
    font-size: $font-size--l;
    line-height: $line-height--l;
  }
}
