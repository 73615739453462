.swal2-container.swal2-shown .Partner_young-family {
  @media only screen and (max-width: $screen-w--l) {
    top: 0;
    transform: translate(-50%, 0)
  }
  @media only screen and (max-width: $screen-w--s) {
    transform: none;
    width: 100%;
    padding: 30px;
    left: 0;
  }
  @media only screen and (max-width: $screen-w--xs) {
    padding: 60px 20px 20px;
  }
}

.Partner_young-family {
  position: relative;
  text-align: left;
  color: #000;
  font-family: "MuseoSansCyrl_500";

  header {
    text-align: center;

    .Partner__IpotekaCenter {
      max-width: 316px;
      @media only screen and (max-width: $screen-w--xs) {
        max-width: 236px;
      }
    }
    .Partner__appeal {
      font-size: 20px;
      line-height: 40px;
      padding: 8px 0 0;
      @media only screen and (max-width: $screen-w--xs) {
        font-size: 14px;
        line-height: 1.4;
        padding: 0;
      }
    }
  }

  .promo {
    &__banner {
      margin: 28px -47px 45px;
      position: relative;
      @media only screen and (max-width: $screen-w--s) {
        margin: 28px -30px 30px;
      }
      @media only screen and (max-width: $screen-w--xs) {
        margin: 18px -20px 20px;
      }

      &-text {
        position: absolute;
        padding: 48px;
        bottom: -4%;
        width: 70%;
        text-align: left;
        @media only screen and (max-width: $screen-w--s) {
          padding: 24px;
        }
        @media only screen and (max-width: $screen-w--xs) {
          padding: 12px;
          bottom: -10%;
        }

        h1 {
          color: #fff;
          font-size: 48px;
          line-height: 1;
          font-weight: 900;
          @media only screen and (max-width: $screen-w--s) {
            font-size: 30px;
          }
          @media only screen and (max-width: $screen-w--xs) {
            font-size: 20px;
          }
        }

        p {
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          @media only screen and (max-width: $screen-w--s) {
            font-size: 18px;
          }
          @media only screen and (max-width: $screen-w--xs) {
            font-size: 14px;
          }
        }
      }
    }

    &__body {
      h2 {
        color: #000;
        font-size: 30px;
        line-height: 1.2;
        @media only screen and (max-width: $screen-w--s) {
          font-size: 25px;
        }
        @media only screen and (max-width: $screen-w--xs) {
          font-size: 20px;
        }
      }

      h3 {
        color: #000;
        font-size: 20px;
        line-height: 1.8;
      }

      ul {
        font-size: 20px;
        line-height: 1.4;
        padding: 0 0 0 20px;
        @media only screen and (max-width: $screen-w--s) {
          font-size: 16px;
        }

        li {
          padding: 0 0 10px 8px;
          @media only screen and (max-width: $screen-w--s) {
            padding: 0 0 8px;
          }
        }
      }
    }

    &__block {
      &-keepinmind {
        background-color: rgba(217, 206, 252, .19);
        background-image: url('/img/young-family/keepinmind.svg');
        background-repeat: no-repeat;
        background-position: 5% 32px;
        margin: 20px -6px 68px -8px;
        padding: 33px 40px 16px 154px;
        font-size: 16px;
        line-height: 23px;
        @media only screen and (max-width: $screen-w--s) {
          margin: 20px -6px 40px -8px;
          padding: 20px 40px 16px 154px;
        }
        @media only screen and (max-width: $screen-w--xs) {
          margin: 20px -6px 28px -8px;
          padding: 134px 24px 16px;
          background-position: center 20px;
        }

        h3 {
          padding: 0 0 9px;
        }
      }

      &-columns {
        display: flex;
        font-size: 20px;
        line-height: 1.4;
        justify-content: space-between;
        @media only screen and (max-width: $screen-w--s) {
          font-size: 16px;
        }
        @media only screen and (max-width: $screen-w--xs) {
          flex-direction: column;
        }
      }

      &-roubles,
      &-family {
        background-image: url('/img/young-family/roubles.svg');
        background-repeat: no-repeat;
        padding: 96px 0 45px;
        width: 46%;
        @media only screen and (max-width: $screen-w--s) {
          background-size: auto 46px;
          padding: 66px 0 24px;
        }
        @media only screen and (max-width: $screen-w--xs) {
          width: 100%;
        }
      }

      &-family {
        background-image: url('/img/young-family/family.svg');
      }
    }
  }

  .Partner__form {
    background-color: rgba(217, 206, 252, .19);
    margin: 0 -48px -48px;
    padding: 60px 50px 40px;
    border-radius: 0 0 5px 5px;
    text-align: center;
    @media only screen and (max-width: $screen-w--s) {
      margin: 0 -30px -30px;
      padding: 40px 30px 30px;
      border-radius: 0;
    }

    h2 {
      color: #000;
      font-size: 30px;
      line-height: 1.2;
      @media only screen and (max-width: $screen-w--s) {
        font-size: 24px;
      }
    }

    p {
      font-size: 20px;
      line-height: 28px;
      margin: 0 auto 45px;
      max-width: 580px;
      @media only screen and (max-width: $screen-w--s) {
        font-size: 18px;
        line-height: 1.4;
        margin: 0 auto 32px;
      }
    }

    label {
      font-family: "MuseoSansCyrl_500";
      font-size: 18px;
      width: 49.5%;
      padding: 0 20px 24px 0;
      text-align: left;
      display: inline-block;
      @media only screen and (max-width: $screen-w--s) {
        font-size: 16px;
        padding: 0 12px 24px 0;
      }
      @media only screen and (max-width: $screen-w--xs) {
        padding: 0 0 24px;
        width: 100%;
      }

      &:nth-child(even) {
        padding: 0 0 24px 20px;
        @media only screen and (max-width: $screen-w--s) {
          padding: 0 0 24px 12px;
        }
        @media only screen and (max-width: $screen-w--xs) {
          padding: 0 0 24px;
        }
      }

      input,
      select {
        width: 100%;
        height: 50px;
        background: rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        border: none;
        padding: 0 15px;
        margin: 12px 0 0;
        outline: none;
      }

      select {
        text-indent: 10px;
      }

      select option {
        width: 100%;
        margin: 0;
      }
    }

    button {
      line-height: 60px;
      color: #fff;
      border: none;
      height: 60px;
      border-radius: 4px;
      box-shadow: 0 14px 24px 0 rgba(125, 92, 228, 0.21);
      background-color: #7d5ce4;
      font-size: 20px;
      font-weight: 900;
      width: 100%;
      margin: 16px 0 25px;
    }

    .Partner__agreement {
      font-size: 18px;
      @media only screen and (max-width: $screen-w--s) {
        font-size: 16px;
      }

      a {
        color: #016eff;
      }
    }
  }
}
