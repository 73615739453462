.conditions-list {
    @media only screen and (min-width: $screen-w--s) {
        display: flex;
        justify-content: space-between;
    }
}

.conditions-text {
  @media only screen and (min-width: $screen-w--s) {
    max-width: 65%;
  }

  p {
    margin-bottom: 5px;
  }
}

.condition-item {
    margin-bottom: 15px;

    &._collapse {
        .condition-item__title {
            border-color: $color-grey--v9;

            &::after {
                transform: rotate(0);
            }
        }

        .condition-item__body {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
            padding-bottom: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (min-width: $screen-w--s) {
        margin-bottom: 0;
        margin-right: 30px;
        width: 33.33333%;

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        color: $color-purple--v1;
        cursor: pointer;
        font-family: $font-family--varRegular;
        font-size: $font-size--l;
        padding: 0 0 12px 0;
        border-bottom: 1px solid transparent;
        transition: border-color 250ms;
        position: relative;
        user-select: none;

        &::after {
            content: '';
            position: absolute;
            width: 16px;
            height: 10px;
            display: block;
            right: 0;
            top: 8px;
            background: url(../img/content/arrow.svg) no-repeat;
            transform: rotate(180deg);

            @media only screen and (min-width: $screen-w--xs) {
                display: none;
            }
        }

        @media only screen and (min-width: $screen-w--xs) {
            color: #000;
            user-select: auto;
            cursor: default;
            border-color: $color-grey--v9 !important;
        }
    }

    &__body {
        color: $color-black;
        max-height: 440px;
        opacity: 1;
        visibility: visible;
        transition: max-height 250ms, opacity 250ms, visibility 250ms;

        @media only screen and (min-width: $screen-w--xs) {
            max-height: none !important;
            opacity: 1 !important;
            visibility: visible !important;
        }

        &-inner {
            padding: 10px 0 15px 0;

            @media only screen and (min-width: $screen-w--xs) {
                padding: 15px 0 20px 0;
            }
        }

        dl {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        dt {
            font-family: $font-family--varBold;
        }

        ul {
            margin: 0;
            padding: 0 0 0 25px;
        }

        li {
            padding: 0 0 0 10px;
            margin: 0 0 10px 0;
        }
    }

    &__important {
        margin-bottom: 25px;
        text-align: center;

        &--mortgage {
            color: #7d5ce4;
        }

        &--refin {
            color: #ff83a2;
        }

        &--pledge {
            color: #bab3e5;
        }

        &--family {
            color: #f98e00;
        }

        b {
            font-family: $font-family--varBold;
            font-weight: normal;
            font-size: 130px;
            line-height: 115px;
        }

        span {
            font-size: 30px;
        }

        p {
            margin: 0;
        }
    }
}
