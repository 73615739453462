$c: ".calculator";

#{$c} {
    padding: 50px 0;

    @media only screen and (min-width: $screen-w--s) {
        padding: 100px 0;
    }

    @media only screen and (min-width: $screen-w--mx) {
        height: 750px;
    }

    &[data-calculator-wr="main"] .calculator__calculating {

        @media only screen and (min-width: 1100px) {
            flex: 0 0 630px;
            max-width: 630px;
        }
    }

    &--ipoteka {
        height: auto;

        .calculator__block {
            display: none;

            &._active {
                display: block;

                @media only screen and (min-width: $screen-w--mx) {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
            }

          .popup-request {
            padding: 0 !important;
            margin: 0 !important;
            align-self: flex-start;
            justify-self: flex-start;
          }
        }

        .calculator__calculating {
            padding-top: 20px;
            padding-bottom: 20px;

            @media only screen and (min-width: $screen-w--s) {
                padding-top: 39px;
                padding-bottom: 20px;
            }
        }

        .calculator__radio {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .control-radio {
                margin: 0 25px 25px 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .range {
            padding: 0;
            margin-bottom: 50px;
        }
    }

    &--popup-all {
        background: #FFF !important;

        @media only screen and (min-width: $screen-w--l) {
            padding-right: 37px;
            padding-left: 37px;
            width: 1000px;
            min-height: 100%;
        }
    }

    &__top {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        .calculator__head {
            margin-bottom: 20px;
            line-height: 1.2;

            @media only screen and (min-width: $screen-w--s) {
                margin: 0 20px 0 0;
            }
        }
    }

    &__changer {
        background: transparent;
        padding: 6px 12px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        color: #7a7a7a;
        transition: color 150ms, background 150ms;
        margin: 0 5px 0 0;

        @media only screen and (min-width: $screen-w--s) {
            margin-right: 0;
            padding: 6px 9px;
        }

        &:hover {
            color: #4c4c4c;
        }

        &:focus {
            outline: none;
        }

        &._active {
            background: #606060;
            color: #fff;
        }
    }

    &__type-btn {
        cursor: pointer;
        margin: 0 20px 20px 0;
        position: relative;
        user-select: none;
        padding-left: 28px;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: grey;
        }

        &._active {
            &::before {
                background-color: $color-purple--v1;
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            border-radius: 100%;
            transition: all 250ms;
        }

        &::before {
            width: 18px;
            height: 18px;
            background: transparent;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 100%;
        }

        &::after {
            content: '';
            background: #fff;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            top: 7px;
            left: 6px;
        }

    }

    &__head {
        color: $color-grey--v4;
    }

    &__info {
        margin-bottom: 20px;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            margin-bottom: 30px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            max-width: 510px;
        }
    }

    &__block {
        @media only screen and (min-width: $screen-w--mx) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__calculating {
        background-color: $color-white;
        box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.05);
        margin: 0 -17px;
        padding: 0 17px;

        @media only screen and (min-width: $screen-w--s) {
            padding: 0 50px 50px;
            margin: 0;
            border-radius: 4px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            flex: 0 0 500px;
            max-width: 500px;
            padding-bottom: 45px;
        }
    }

    &__list-parametrs {
        list-style: none;
        font-size: $font-size--sl;

        li {
            margin-bottom: 15px;

            @media only screen and (min-width: $screen-w--s) {
                margin-bottom: 12px;
            }

            @media only screen and (min-width: $screen-w--mx) {
                margin-bottom: 14px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__price {
        padding: 22px 0 0;

        &--recredit {
            display: none;

            &._active {
                display: block;
            }
        }

        @media only screen and (min-width: $screen-w--s) {
            padding-top: 40px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            flex: 0 0 400px;
            max-width: 370px;
            padding: 21px 0 0 50px;
        }

        ul {
            list-style: none;
        }

        li {
            margin-bottom: 20px;
            color: #7a7a7a;
            // opacity: .6;

            @media only screen and (min-width: $screen-w--s) {
                margin-bottom: 28px;
            }

            &:nth-child(1) {
                color: $color-black--v2;
                opacity: 1;

                #{$c}__price-name {
                    margin-bottom: 3px;

                    @media only screen and (min-width: $screen-w--s) {
                        line-height: 1.11;
                    }
                }

                #{$c}__price-value {
                    font-size: $font-size--lx;
                    margin-bottom: 3px;

                    .odometer .odometer-value {
                        width: 20px;

                        @media only screen and (min-width: $screen-w--s) {
                            width: 34px;
                        }
                    }

                    @media only screen and (min-width: $screen-w--s) {
                        font-size: 50px;
                        line-height: 1;
                        margin-bottom: 10px;
                    }
                }

                #{$c}__price-level {
                    span {
                        background-color: #333333;
                    }
                }
            }

            &:nth-child(2) {
                color: #7a7a7a;
                // opacity: .6;

                .calculator__block[data-calculator="recredit"] & {
                    color: $color-purple--v1;
                    opacity: 1;
                }

                #{$c}__price-level {
                    span {
                        background-color: #7D5CE4;
                    }
                }
            }

            &:nth-child(3) {
                color: #7a7a7a;
                // opacity: .6;

                #{$c}__price-level {
                    span {
                        background-color: rgba(122,122,122,0.98);
                        width: 100%;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    &__msg {
        display: none;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 0 30px;

        &._active {
            display: flex;
        }

        i {
            font-size: 106px;
            font-style: normal;
        }

        p {
            margin-top: 30px;
            font-size: 18px;
            line-height: 27px;
        }
    }

    &__actions {
        margin: 30px 0 0 0;

        p {
            margin: 15px 0 0;
            font-size: 14px;
            color: #78797a;
        }

        // .btn {
        //     width: auto;
        // }
    }

    &__price-name {
        font-size: $font-size--s;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            line-height: $line-height--xl;
        }
    }

    &__price-value {
        font-size: $font-size--l;
        margin-bottom: 5px;
        font-family: $font-family--varBold;
        line-height: $line-height--s;
        white-space: nowrap;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--lx;
            line-height: 1.33;
        }
    }

    &__price-level {
        position: relative;
        width: 100%;
        height: 10px;
        border-radius: 10px;

        span {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 25%;
            bottom: 0;
            border-radius: 10px;
            transition: width 0.3s ease-out;
        }
    }
}
