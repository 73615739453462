.badge {
    font-size: $font-size--s;
    line-height: $line-height--s;
    text-transform: uppercase;
    padding: 3px 2px 3px 5px;
    border-radius: 2px;
    letter-spacing: 3px;
    text-align: center;
}

.badge-gray {
    background: $color-grey--v12;
}
