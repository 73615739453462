h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family--varBold;
    color: $heading-color;
    font-style: normal;
    font-weight: 900;
}

h1 {
    font-size: 30px;
    line-height: $line-height--sm;
    margin-bottom: 10px;

    @media only screen and (min-width: $screen-w--s) {
        font-size: 80px;
        line-height: $line-height--s;
        margin-bottom: 22px;
    }
}

h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: $line-height--sm;

    @media only screen and (min-width: $screen-w--s) {
        font-size: $font-size--xxl;
        margin-bottom: 20px;
        line-height: $line-height--sl;
    }
}

p {
    margin: 0 0 15px;
}

a {
    color: $link-color;
    text-decoration: none;
}

.text-color {
    color: $color-grey--v4 !important;
}

.pb-30 {
    padding-bottom: 20px !important;
}

.pb-xs-30 {
    @media only screen and (max-width: $screen-w--xs) {
        padding-bottom: 20px !important;
    }
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;
}
