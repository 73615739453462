.range {
    padding: 20px 0 34px;

    @media only screen and (min-width: $screen-w--mx) {
        padding-top: 39px;
        padding-bottom: 65px;
    }

    &__head {
        @include clearfix();

        margin-bottom: 13px;

        @media only screen and (min-width: $screen-w--mx) {
            margin-bottom: 2px;
        }
    }

    &__name {
        font-size: $font-size--sl;
        line-height: $line-height--sl;
        float: left;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            line-height: $line-height--xxl;
        }
    }

    &__result {
        font-family: $font-family--varRegular;
        font-size: $font-size--sl;
        line-height: $line-height--sl;
        float: right;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            line-height: $line-height--xxl;
        }
    }

    &__result-input {
        display: inline-block;
        vertical-align: top;
        max-width: 90px;
        font-family: $font-family--varRegular;
        font-size: $font-size--sl;
        line-height: $line-height--sl;
        padding: 0;
        border: none;
        background-color: transparent;
        outline: none;
        text-align: right;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            line-height: $line-height--xxl;
            max-width: 120px;
        }
    }

    &__input {
        position: relative;
        padding: 12px 0;

        @media only screen and (min-width: $screen-w--s) {
            padding: 17px 0;
        }

        @media only screen and (min-width: $screen-w--mx) {
            padding: 12px 0;
        }
    }

    &__min-value,
    &__max-value {
        position: absolute;
        bottom: -12px;
        font-size: $font-size--s;
        line-height: $line-height--xl;
        color: #9b9b9b;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--sl;
            line-height: 2.57;
            bottom: -18px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            bottom: -24px;
        }
    }

    &__min-value {
        left: 0;
    }

    &__max-value {
        right: 0;
    }
}
