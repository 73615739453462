.partners {
  padding-top: 50px;
  padding-bottom: 30px;

  @media only screen and (min-width: $screen-w--s) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .container {
    position: relative;


    .carousel-plate__nav {
      top: -3px;

      @media only screen and (min-width: $screen-w--s) {
        top: 8px;
      }
    }
  }

  &__head {
    margin-bottom: 25px;
    color: $color-black;

    @media only screen and (min-width: $screen-w--s) {
      margin-bottom: 35px;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 0 auto;
    justify-content: left;
    gap: 20px 0;
  }
  &__item {
    height: 45px;
    width: 25%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &--alfabank {
      padding-top: 15px;
    }
    &--rshb img {
      height: 60px !important;
    }
    &--primsocbank img {
      height: 80px !important;
    }
    &--vtb img {
      height: 30px !important;
    }
    @media only screen and (min-width: $screen-w--s) {
      height: 120px;
      padding-left: 26px;
      padding-right: 26px;
    }

    @media only screen and (max-width: $screen-w--s) {
      img {
        max-height: 30px;
      }
      #img-alfabank{
        max-height: 25px;
      }

    }
    @media only screen and (max-width: $screen-w--xs) {
      &--alfabank {
        padding-top: 5px;
      }
      &--vtb img {
        height: 15px !important;
      }
    }



    @media only screen and (min-width: $screen-w--m) {
      height: 120px;

      img {
        max-height: 54px;
      }
    }
  }
}
