.trade {
    position: relative;
    padding-top: 50px;
    overflow: hidden;

    @media only screen and (min-width: $screen-w--s) {
        padding-top: 95px;
    }

    @media only screen and (min-width: $screen-w--s) {
        min-height: 750px;
    }

    &__block {
        margin-bottom: 35px;
        color: $color-white;

        @media only screen and (min-width: $screen-w--s) {
            width: 53.333333%;
            margin: 0;
            padding-bottom: 76px;
        }

        @media only screen and (min-width: $screen-w--l) {
            width: 480px;
            padding-bottom: 186px;
        }
    }

    &__step-name {
        text-transform: uppercase;
        letter-spacing: 1.7px;
        font-family: $font-family--varBold;
        font-size: 11px;
        opacity: 0.7;
        margin-bottom: 6px;

        @media only screen and (min-width: $screen-w--s) {
            letter-spacing: 2px;
            font-size: 13px;
            margin-bottom: 4px;
        }
    }

    &__info {
        margin-bottom: 28px;

        br {
            display: none;
        }

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;

            br {
                display: block;
            }
        }

        @media only screen and (min-width: $screen-w--l) {
            margin-bottom: 38px;
        }

        a {
            color: $color-blue--v2;
        }
    }

    &__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -12px 28px;

        @media only screen and (min-width: $screen-w--s) {
            margin: 0 -14px 37px;
            justify-content: flex-start;
        }

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 12px;

            &:nth-child(2) {
                .trade__list-icon {
                    background-image: url('../img/content/alternative-transaction.png');
                    background-size: 77px 61px;

                    @media only screen and (min-width: $screen-w--l) {
                        background-size: 95px 75px;
                    }
                }
            }

            @media only screen and (min-width: $screen-w--s) {
                padding: 0 14px;

                &:nth-child(1) {
                    flex: 0 0 53.333333%;
                    max-width: 53.333333%;
                }

                &:nth-child(2) {
                    flex: 0 0 46.666666%;
                    max-width: 46.666666%;
                }
            }

            @media only screen and (min-width: $screen-w--l) {
                &:nth-child(1) {
                    flex: 0 0 270px;
                    max-width: 270px;
                }

                &:nth-child(2) {
                    flex: 0 0 238px;
                    max-width: 238px;
                }
            }
        }
    }

    &__list-icon {
        font-size: 60px;
        height: 61px;
        width: 77px;
        margin-bottom: 14px;

        @media only screen and (min-width: $screen-w--l) {
            font-size: 75px;
            height: 75px;
            width: 95px;
            margin-bottom: 20px;
        }
    }

    &__list-name {
        font-size: $font-size--m;
        margin-bottom: 7px;

        @media only screen and (min-width: $screen-w--l) {
            font-size: $font-size--l;
            margin-bottom: 10px;
        }
    }

    &__list-text {
        font-size: $font-size--sl;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--m;
        }
    }

    &__button {
        @media only screen and (min-width: $screen-w--s) {
            width: 215px;
        }
    }

    &__illustration {
        position: relative;
        height: 269px;
        list-style: none;

        @media only screen and (min-width: $screen-w--s) {
            height: auto;
            position: absolute;
            top: 0;
            right: 150px;
        }

        @media only screen and (min-width: $screen-w--l) {
            right: -190px;
        }

        li {
            position: absolute;
            background-repeat: no-repeat;
            margin: 0 auto;
            right: 0;
            transform: translateY(200%);

            @media only screen and (min-width: $screen-w--s) {
                transform: translateX(200%);
            }

            @media only screen and (min-width: $screen-w--l) {
                transform: translateX(400%);
            }

            @media only screen and (min-width: 2000px) {
                transform: translateX(800%);
            }

            &.active {
                transform: translateY(0);

                @media only screen and (min-width: $screen-w--s) {
                    transform: translateX(0);
                }
            }

            &:nth-child(1) {
                background-image: url('../img/content/paper-1.png');
                width: 272px;
                height: 280px;
                background-size: 272px 280px;
                left: 28px;
                top: -11px;
                transition: transform 0.4s ease-out 0s;

                @media only screen and (min-width: $screen-w--s) {
                    background-image: url('../img/content/paper-1-l.png');
                    width: 473px;
                    height: 623px;
                    background-size: 473px 623px;
                    left: auto;
                    right: -328px;
                    top: -11px;
                }

                @media only screen and (min-width: $screen-w--l) {
                    background-image: url('../img/content/paper-1-l.png');
                    width: 473px;
                    height: 623px;
                    background-size: 473px 623px;
                    left: auto;
                    top: -11px;
                    right: 0;
                }
            }

            &:nth-child(2) {
                background-image: url('../img/content/paper-2.png');
                width: 292px;
                height: 262px;
                background-size: 292px 262px;
                left: -20px;
                top: 8px;
                transition: transform 0.4s ease-out 0.4s;

                @media only screen and (min-width: $screen-w--s) {
                    background-image: url('../img/content/paper-1-l.png');
                    width: 473px;
                    height: 623px;
                    background-size: 473px 623px;
                    left: auto;
                    right: -257px;
                    top: 20px;
                }

                @media only screen and (min-width: $screen-w--l) {
                    background-image: url('../img/content/paper-1-l.png');
                    width: 473px;
                    height: 623px;
                    background-size: 473px 623px;
                    right: 55px;
                }
            }

            &:nth-child(3) {
                background-image: url('../img/content/paper-3.png');
                width: 150px;
                height: 94px;
                background-size: 150px 94px;
                left: -100px;
                top: auto;
                bottom: 0;
                transition: transform 0.4s ease-out 0.8s;

                @media only screen and (min-width: $screen-w--s) {
                    background-image: url('../img/content/paper-3-l.png');
                    width: 244px;
                    height: 314px;
                    background-size: 244px 314px;
                    left: auto;
                    top: 292px;
                    right: -73px;
                }

                @media only screen and (min-width: $screen-w--l) {
                    background-image: url('../img/content/paper-3-l.png');
                    width: 244px;
                    height: 314px;
                    background-size: 244px 314px;
                    right: 240px;
                }
            }
        }

        @media only screen and (min-width: $screen-w--s) {

        }

        @media only screen and (min-width: $screen-w--l) {

        }
    }
}
