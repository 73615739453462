.range-slider,
.range-slider__fill {
    display: block;
    border-radius: 6px;
}

.range-slider {
    position: relative;
    background-color: rgba(0, 0, 0, 0.08);

    &__horizontal {
        height: 6px;
        width: 100%;

        @media only screen and (min-width: $screen-w--s) {
            height: 8px;
        }
    }

    &__fill {
        background: $color-purple--v1;
        position: absolute;
        z-index: 2;
    }

    &__fill__horizontal {
        height: 100%;
        top: 0;
        left: 0;
    }

    &__handle {
        cursor: pointer;
        display: inline-block;
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 3;
        box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.04);
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 100%;
        background-color: $color-white;

        @media only screen and (min-width: $screen-w--s) {
            height: 42px;
            width: 42px;
            box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.04);
        }

        @media only screen and (min-width: $screen-w--mx) {
            height: 30px;
            width: 30px;
        }

        &:active {
            background-color: $color-grey--v1;
        }
    }

    &__handle__horizontal {
        top: -12px;

        @media only screen and (min-width: $screen-w--s) {
            top: -18px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            top: -12px;
        }
    }

    &__buffer {
        z-index: 1;
        position: absolute;
        top: 3px;
        height: 14px;
        background: #2c3e50;
        border-radius: 10px;
    }
}

.range-slider--disabled {
    opacity: 0.4;
}

input[type="range"]:focus + .range-slider .range-slider__handle {
    // box-shadow: 0 0 8px rgba(142, 68, 173, 0.9);
}
