.title-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;


    h1{
        color: #1e1f21;
        font-size: 40px;

        @media only screen and (max-width: $screen-w--m) {
            font-size: 26px;
        }
    }

    a{
        font-size: 20px;
        color: #016eff;

        @media only screen and (max-width: $screen-w--m) {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%)
        }

        &:hover{
            color: #87CEFA;
        }
    }

}

