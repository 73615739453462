@include font-face(
  'Icomoon',
  '../fonts/icomoon/icomoon',
  normal,
  normal,
  null,
  woff ttf svg
);
@include font-face(
  'sf_ui_displaybold',
  '../fonts/sfUiDisplay/sf-ui-display-bold-webfont',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'sf_ui_displayregular',
  '../fonts/sfUiDisplay/sf-ui-display-regular-webfont',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'FiraSansMedium',
  '../fonts/FiraSans/FiraSans-Medium',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'FiraSans',
  '../fonts/FiraSans/FiraSans-Regular',
  normal,
  normal,
  null,
  woff2 woff
);
// @include font-face("OpenSans", "../fonts/OpenSans/OpenSans-Regular", normal, normal, null, woff2 woff);
@include font-face(
  'MuseoSansCyrl_500',
  '../fonts/Museo-Sans-Cyr/museosanscyrl-500-webfont',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'MuseoSansCyrl_900',
  '../fonts/Museo-Sans-Cyr/museosanscyrl-900-webfont',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'HelveticaNeueDeskInterface',
  '../fonts/HelveticaNeueDeskInterface/HelveticaNeueDeskInterface-Regular',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_400',
  '../fonts/BasisGrotesquePro/basis-grotesque-regular-pro',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_500',
  '../fonts/BasisGrotesquePro/basis-grotesque-medium-pro',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_700',
  '../fonts/BasisGrotesquePro/basis-grotesque-bold-pro',
  bold,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_900',
  '../fonts/BasisGrotesquePro/basis-grotesque-black-pro',
  bold,
  normal,
  null,
  woff2 woff
);
