.feats {
    margin-top: 40px;

    @media only screen and (min-width: $screen-w--s) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.feat {
    text-align: center;
    margin-bottom: 27px;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (min-width: $screen-w--s) {
        width: 25%;
        margin-right: 30px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &--third {
        @media only screen and (min-width: $screen-w--s) {
            width: 33.33333%;
        }
    }

    &__img {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media only screen and (min-width: $screen-w--s) {
            height: 131px;
            margin-bottom: 0;
        }

        img {
            @media only screen and (min-width: $screen-w--s) {
                max-width: 74px;
            }
        }
    }

    &__title {
        color: $color-grey--v4;
        font-size: 16px;
        line-height: 19px;
        font-family: $font-family--varBold;
        margin: 0 0 8px 0;

        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 10px;
            font-size: $font-size--m;
            line-height: inherit;
        }
    }

    p {
        font-size: 14px;
        line-height: 17px;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--m;
            line-height: inherit;
        }
    }
}
