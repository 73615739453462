.swal2-container.swal2-shown {
    background-color: rgba(0, 0, 0, 0.8) !important;
    font-family: "sf_ui_displayregular";

    .swal2-popup.swal2-modal{
        @media only screen and (max-width: $screen-w--l) {
            position: fixed;
            border-radius: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-family: "sf_ui_displayregular";
            font-size: 14px;
            color: #1e1f21;
            background: #fff;

            .partner-modal__info {
                margin: 20px 0px;
            }
        }
    }

    & .partner-modal__wrap {
        margin: 0 auto;
        width: 100%;
        max-width: 800px;
        padding: 1.5em;
        @media only screen and (max-width: $screen-w--l) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
        }
        @media only screen and (max-width: $screen-w--s) {
            transform: none;
            width: 100%;
            padding: 50px;
            top: 10%;
            left: 0;
        }
        @media only screen and (max-width: $screen-w--xs) {
            padding: 20px;
        }
    }

    & .partner-modal__content {
        @media only screen and (min-width: $screen-w--l) {
            display: flex;
            width: auto;
        }
        @media only screen and (max-width: $screen-w--l) {
            overflow-y: scroll;
            position: relative;
            display: block !important;
        }

        .swal2-content .partner-logo{
            margin-bottom: 5px;
            height: 54px;
        }

        .partner-modal_name{
            letter-spacing: 2px;
            color: #78797a;
            font-size: 0.8em;
            line-height: 1.93;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        .partner-modal__info {
            margin: 20px 0px;
        }


        .swal2-header{
            .swal2-close{
                color: black;
                font-size: 50px;
                font-weight: bolder;
                z-index: 10;
                outline: none;
        }
    }
}
    .partner-modal__success-content {
        padding: 50px !important;

        .swal2-header{
            .swal2-close{
                color: black;
                font-size: 50px;
                font-weight: bolder;
            }
        }
    }
}
