$t1: 700ms;
$d2: $t1 - 250ms;
$d3: $d2 + $d2;
$d4: $d3 + $d2;
$d5: $d4 + $d2;
$d6: $d5 + $d2;

.chat
{
    &.chat--popap {
        .chat-questions {
            animation: bounceIn 600ms;
            animation-fill-mode: forwards;
        }

        .chat-answers {
            .btn {
                animation: bounceIn 600ms;
                animation-fill-mode: forwards;

                &:nth-child(1) {
                    animation-delay: 400ms;
                }
                &:nth-child(2) {
                    animation-delay: 500ms;
                }
                &:nth-child(3) {
                    animation-delay: 600ms;
                }
                &:nth-child(4) {
                    animation-delay: 700ms;
                }
                &:nth-child(5) {
                    animation-delay: 700ms;
                }
            }
        }

        .chat-answers {
            .btn {
                opacity: 0;
            }
        }
    }

    &.chat--main {

        &._active {
            .chat-questions {
                p {
                    &::before {
                        animation: bounceIn $t1;
                        animation-fill-mode: forwards;
                    }
                }

                span {
                    animation: bounceIn $t1;
                    animation-fill-mode: forwards;
                    animation-delay: $d2;
                }
            }

            .chat-answers {
                .btn {
                    // background: rgb(174, 178, 191);
                    animation: bounceIn $t1;
                    animation-fill-mode: forwards;
                    opacity: 0;

                    &:nth-child(1) {
                        animation-delay: $d3;
                    }
                    &:nth-child(2) {
                        animation-delay: $d4;
                    }
                    &:nth-child(3) {
                        animation-delay: $d5;
                    }
                    &:nth-child(4) {
                        animation-delay: $d6;
                    }
                }
            }
        }


        .chat-questions {
            p {
                &::before {
                    opacity: 0;
                }
            }

            span {
                opacity: 0;
            }
        }

        .chat-answers {
            .btn {
                opacity: 0;
            }
        }
    }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: 0.75s;
  animation-name: bounceIn;
}