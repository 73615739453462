.ipoteka-fs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 70px 0 0;
    box-shadow: 0 3px 47px 0 rgba(0, 0, 0, 0.08);
    background: #ddd;
    position: relative;
    overflow-x: hidden;

    @media only screen and (min-width: $screen-w--s) {
        height: 680px;
        padding: 0 0 110px;
        justify-content: center;
    }

    @media only screen and (min-width: $screen-w--m) {
        height: 800px;
    }

    &__bg {
        position: absolute;
        right: 0;
        background: center / cover no-repeat url('/img/cube-home-poster.jpg');
        z-index: 0;
        display: none;
        transition: 150ms background-color ease-in-out;

        video {
            width: 100%;
            height: auto;
            display: block;
            opacity: 0;
        }

        canvas {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        @media only screen and (min-width: $screen-w--s) {
            display: block;
            width: 410px;
            height: 320px;
            right: -20px;
            top: -48px;
        }

        @media only screen and (min-width: $screen-w--m) {
            top: -75px;
            right: 4px;
            width: 566px;
            height: 450px;
        }
    }

    &__container {
        width: 100%;
    }

    .video {
        &::before {
            display: none;
        }
    }
}
