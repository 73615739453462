.yellow-shutter {
    max-width: 1080px;

    &__head {
        display: flex;
        flex-direction: column-reverse;
        background-image: linear-gradient(324deg, #ffdd02, #faa919);
        padding: 60px 35px 60px 25px !important;

        @media only screen and (min-width: $screen-w--s) {
            justify-content: space-between;
            flex-direction: row;
            background-image: linear-gradient(290deg, #ffdd02, #faa919);
            padding: 60px 100px 40px 60px !important;
        }

        &-left {
            @media only screen and (min-width: $screen-w--s) {
                max-width: 70%;
            }
        }
        &-right {
        }
    }

    &__title {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: normal;
        color: #1e1f21;
        margin-bottom: 10px !important;

        @media only screen and (min-width: $screen-w--s) {
            font-size: 50px;
            line-height: 1;
        }
    }

    &__desc {
        font-size: 20px;
        line-height: 1.4;
        text-align: left;
    }

    &__logo {
        width: 100px;
        margin-bottom: 30px;

        @media only screen and (min-width: $screen-w--s) {
            width: 150px;
            margin-bottom: 0;
        }
    }

    &__body {
        padding: 40px 25px;
        padding-bottom: 0 !important;

        @media only screen and (min-width: $screen-w--s) {
            padding: 60px;
        }
    }

    &__row {
        margin-left: -10px;
        margin-right: -10px;

        @media only screen and (min-width: $screen-w--s) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__col {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__card {
        text-align: center;
        margin-bottom: 40px !important;

        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 0 !important;
        }
    }

    &__icon {
        &-wrap {
            margin-bottom: 20px;

            @media only screen and (min-width: $screen-w--s) {
                margin-bottom: 0;
                min-height: 100px;
            }
        }
    }

    &__icon-text {
        font-weight: bold;
        padding: 0 10px;
    }

    &__callback {
        padding-top: 60px;

        &-title {
            color: #1e1f21;
        }

        &-input {
            background-color: #f0f0f0;
        }

        &-footer {
            margin-top: 30px;
        }

        &-phone {
            font-size: 25px;
            color: #016eff !important;

            &:hover {
                color: darken(#016eff, 5%) !important;
            }
        }
    }
}
