.swal2-container.swal2-shown {
    background-color: rgba(0, 0, 0, 0.8) !important;
    font-family: "sf_ui_displayregular";

    .swal2-popup.swal2-modal{
        @media only screen and (max-width: $screen-w--l) {
            position: fixed;
            border-radius: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-family: "sf_ui_displayregular";
            font-size: 14px;
            color: #1e1f21;
            background: #fff;
        }
    }

    & .onlending-modal__wrap {
        margin: 0 auto;
        width: 800px;
        padding: 10px;
        @media (min-width: 768px) and (max-width: 991px)  {
           width: 740px !important;
        }

        @media only screen and (max-width: $screen-w--s) {
            width: 100%;
            padding: 50px;
            top: 10%;
            left: 0;
            text-align: left !important;
            padding: 0px 20px 20px 20px;
        }
        @media only screen and (max-width: $screen-w--xs) {
            padding: 0px 20px 20px 20px;
        }
    }

        & .onlending-modal__content {

                form {
                    hr{
                        border: solid 1px #e1e1e1;
                    }

                    .form-controls__control-select {
                        height: 50px
                    }

                    @media only screen and (max-width: $screen-w--l) {
                    text-align: left !important;

                    .modal-form-controls__row{


                        .form-controls__control-input{
                            margin-top: 10px;
                            margin-bottom: 20px;
                        }

                    }
                }

                @media only screen and (max-width: $screen-w--l) {

                .onlending-modal__submit{
                    margin: 20px 0px;
                }
            }
                @media only screen and (min-width: $screen-w--l) {
                text-align: left;

                .modal-form-controls__row{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    line-height: 2.5;
                    margin-top: 10px;

                    .modal-form-controls__control-name{
                        width: 100%;
                        padding: 0px 20px;
                    }

                    .modal-form-controls__control{
                        width: 50%;
                        padding: 0px 20px;
                        display: block;
                    }
                }
                hr{
                    margin: 0px 20px;
                }
            }
            @media only screen and (min-width: $screen-w--l) {
            .onlending-modal__submit{
                max-width: 95%;
                margin: 20px 20px;
            }
        }

        .form-controls__error.is-error{
                    float: none !important;
                    margin: -10px 0 20px 0  !important;


        }

        }


        .onlending-link_agreement{
            text-align: left;
            padding: 0 20px;
        }

            a{
                color: #016eff !important;
            }

            @media only screen and (min-width: $screen-w--l) {
                display: flex;
                width: auto;
            }
            @media only screen and (max-width: $screen-w--l) {
                overflow-y: scroll;
                position: relative;
                display: block !important;
                .onlending-link_agreement{

                    padding: 0px;
                }
            }

            .swal2-content {
                color: #1e1f21;
                .onlending-block-logo{
                    text-align: center;
                    .onlending-logo{
                       margin: 0px 0px 20px 0px;
                       height: 35px;
                     }
                }
        }

            .swal2-header{
                .swal2-close{
                    color: black;
                    font-size: 50px;
                    font-weight: bolder;
                    outline: none !important;
            }
        }


}

    .onlending-modal__success-content{
        width: 800px;
        height: 430px;
        color: #1e1f21;
        .onlending-modal-success{
        padding: 0px 50px !important;
        }
        .swal2-header{
            .swal2-close{
                color: black;
                font-size: 50px;
                font-weight: bolder;
                outline: none !important;
        }
    }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
}
