form,
.form {
    input,
    textarea {
        outline: none;
    }
}

fieldset {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}

.form {
    margin-bottom: 20px;

    @media only screen and (min-width: $screen-w--s) {
        margin-bottom: 10px;
    }

    &__group {
        position: relative;
    }
}
