.coupons {
    margin-top: 0;
    margin-left: -10px;
    margin-right: -10px;

    @media only screen and (min-width: $screen-w--s) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__red {
        color: $color-pink--v2;
    }

    &__green {
        color: $color-ligth-green--v2;
    }

    &__blue {
        color: $color-purple--v1;
    }
}

.coupon-wrap {
    padding: 0 10px;

    &:last-child {
        & .coupon {
            margin-bottom: 0;
        }
    }

    @media only screen and (min-width: $screen-w--s) {
        width: 50%;
    }

    & > a {
        &:hover {
            & .coupon {
                @media only screen and (min-width: $screen-w--s) {
                    background-color: darken($color-grey--l6, 3%);
                    border: solid 1px darken($color-grey--l3, 5%);
                }
            }

            & .coupon__img {

            }
        }

        & .coupon {
            @media only screen and (min-width: $screen-w--s) {
                transition: background-color 0.2s, border 0.2s;
            }
        }
    }

    & .coupon {
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: $color-grey--l6;
        border: solid 1px $color-grey--l3;

        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 30px;
        }

        &__img {
            background-repeat: no-repeat;
            background-size: cover;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding-top: 46.296%;

            @media only screen and (min-width: $screen-w--s) {
                padding-top: 58.547%;
                background-position: center;
                position: relative;
            }
        }

        &__footer {
            padding: 15px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (min-width: $screen-w--s) {
                padding: 20px;
            }

            &-info {
                line-height: 1.3;
                display: flex;
                -webkit-box-orient: vertical!important;
                -webkit-box-direction: reverse!important;
                -ms-flex-direction: column-reverse!important;
                flex-direction: column-reverse!important;
                margin-right: 20px;

                @media only screen and (min-width: $screen-w--s) {
                    -webkit-box-direction: normal!important;
                    -ms-flex-direction: column!important;
                    flex-direction: column!important;
                }
            }

            &-label {
                font-size: 12px;
                line-height: 22px;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: $color-grey--v17;
            }

            &-sale {
                font-size: 30px;
                font-family: $font-family--varBold;
                line-height: 1;

                @media only screen and (min-width: $screen-w--s) {
                    font-size: 40px;
                }
            }
        }
    }
}
