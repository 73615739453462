.buy-steps {

}

.buy-step {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-grey--v9;
    padding: 15px 0;

    &:last-child {
        border-bottom: 0;
    }

    &__number {
        flex-shrink: 0;
        padding: 0 17px;
        text-align: center;
        font-family: $font-family--varBold;
        font-size: 20px;
        color: $color-black;

        @media only screen and (min-width: $screen-w--xs) {
            font-size: 36px;
            padding: 0 20px;
        }

        @media only screen and (min-width: $screen-w--s) {
            margin-right: 15px;
        }
    }

    &__desc {
        width: 100%;
        font-size: 14px;

        @media only screen and (min-width: $screen-w--xs) {
            font-size: 16px;
        }

        @media only screen and (min-width: $screen-w--s) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        p {
            margin: 0 0 5px 0;

            @media only screen and (min-width: $screen-w--s) {
                max-width: 70%;
                margin-bottom: 0;
            }

        }

        span {
            color: #78797a;
        }
    }
}