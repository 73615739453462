html {
  box-sizing: border-box;
  font-size: $font-size--m;
}

body {
  background: $bg-main;
  font-family: $font-family--varRegular;
  font-size: $font-size--m;
  line-height: $line-height--m;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
}

img {
  margin: 0 auto;
  max-width: 100%;
}

.hidden {
  display: none !important;
}

.hidden-s {
  display: block !important;

  @media (min-width: $screen-w--s) {
    display: none !important;
  }
}

.hidden-mx {
  display: block !important;

  @media (min-width: $screen-w--mx) {
    display: none !important;
  }
}

.hidden-l {
  display: block !important;

  @media (min-width: $screen-w--l) {
    display: none !important;
  }
}

.visible-s {
  display: none !important;

  @media (min-width: $screen-w--s) {
    display: block !important;
  }
}

.visible-mx {
  display: none !important;

  @media (min-width: $screen-w--mx) {
    display: block !important;
  }
}

.visible-l {
  display: none !important;

  @media (min-width: $screen-w--l) {
    display: block !important;
  }
}

.hidden-s-inline-block {
  display: inline-block !important;

  @media (min-width: $screen-w--s) {
    display: none !important;
  }
}

.hidden-mx-inline-block {
  display: inline-block !important;

  @media (min-width: $screen-w--mx) {
    display: none !important;
  }
}

.hidden-l-inline-block {
  display: inline-block !important;

  @media (min-width: $screen-w--l) {
    display: none !important;
  }
}

.visible-s-inline-block {
  display: none !important;

  @media (min-width: $screen-w--s) {
    display: inline-block !important;
  }
}

.visible-mx-inline-block {
  display: none !important;

  @media (min-width: $screen-w--mx) {
    display: inline-block !important;
  }
}

.visible-l-inline-block {
  display: none !important;

  @media (min-width: $screen-w--l) {
    display: inline-block !important;
  }
}
