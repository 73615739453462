.articles {
  .btn {
    margin-top: 30px;
    max-width: 185px;

    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .color--white {
    color: white;
  }

  &__promo {
    list-style: none;

    @media only screen and (min-width: $screen-w--mx) {
      display: flex;
      flex-wrap: wrap;
    }

    &-content {
      width: 100%;
      height: 100%;
      max-width: 1000px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: right center;
      position: relative;

      @media only screen and (min-width: $screen-w--s) {
        &:after {
          height: 275px;
          width: 293px;
        }
      }

      @media only screen and (min-width: 960px) {
        &:after {
          height: 275px;
          width: 293px;
          position: absolute;
          right: 2em;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    .articles__promo-item {
      position: relative;
      width: 100%;
      height: 290px;

      @media only screen and (min-width: $screen-w--s) {
        height: 445px;
      }

      @media only screen and (min-width: $screen-w--mx) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      &--50 {
        @media only screen and (min-width: $screen-w--mx) {
          flex: 0 0 50%;
          max-width: 50%;
          height: 350px;
        }
      }

      &--100 {
        @media only screen and (min-width: 960px) {
          flex: 0 0 100%;
          max-width: 100%;
          height: 410px;
        }
      }
    }

    &-block {
      padding: 0 40px;
      position: relative;
      height: 100%;
      display: block;
      overflow: hidden;

      @media only screen and (max-width: $screen-w--xs) {
        padding: 0 20px;
      }

      @media only screen and (min-width: $screen-w--m) {
        padding: 0 17px;
      }

      &--light {
        color: #fff;
      }

      &--light-green {
        background-color: $color-ligth-green--v1;
      }

      &--amber {
        background-color: $color-amber--v1;
      }

      &--dark-blue {
        background-color: #47cbf7;
        color: #000;
        background-image: url('../img/content/loyality.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: contain;

        @media only screen and (max-width: $screen-w--xs) {
          background-position: bottom center;
          background-size: 150%;
        }

        @media only screen and (max-width: 767px) {
          background-image: none;
        }
      }

      &--light-blue {
        background-color: #351a7e;
        background-image: url('../img/content/experts.svg'),
        linear-gradient(to right, #cbf0f9, rgba(206, 240, 249, 0));
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media only screen and (max-width: $screen-w--xs) {
          background-image: linear-gradient(
              to right,
              #cbf0f9,
              rgba(206, 240, 249, 0)
          );
        }

        @media only screen and (max-width: 767px) {
          background-image: none;
        }
      }

      &--request-form {
        background: linear-gradient(165deg, #9486DD 3.42%, #A095E3 44.69%, #6756D2 99.23%);
        color: #000;

        > div {
          background-image: url('../img/content/regina.png');

          @supports (background-image: url('../img/content/regina.webp')) {
            background-image: url('../img/content/regina.webp');
          }
          background-size: contain;
          background-position: right;
          background-repeat: no-repeat;
          object-fit: contain;
          max-width: 90%;
          margin: auto;

          @media only screen and (max-width: $screen-w--xs) {
            background-position: bottom center;
            background-size: 150%;
          }

          @media only screen and (max-width: 767px) {
            background-image: none;
          }

          @media only screen and (min-width: $screen-w--s) and (max-width: $screen-w--xl) {
            max-width: unset;
          }

          @media only screen and (min-width: $screen-w--xxl) {
            max-width: 80%;
          }
        }
      }

      .block-success {
        padding-top: 10px !important;
      }

      .popup-request {
        @media only screen and (max-width: $screen-w--s) {
          padding: 0;
          margin: 20px 0;
          font-size: 16px;

          .block-sending__agreement {
            font-size: 14px;
            line-height: 1.4em;
            color: #ffffff;

            a {
              color: #ffffff;
              text-decoration: underline;
            }
          }
        }
        @media only screen and (min-width: $screen-w--s) and (max-width: $screen-w--xl) {
          padding: 50px;
          margin: 30px 0;
          border-radius: 4px;
          background: #FFF;
          box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.10);
        }

        @media only screen and (min-width: $screen-w--xl) {
          padding: 50px;
          margin: 30px 10%;
          border-radius: 4px;
          background: #FFF;
          box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.10);
        }
      }

      @media only screen and (min-width: $screen-w--mx) {
        padding: 0 55px;
      }

      .submit-form-newflats {
        display: block;
        width: 100%;
        max-width: unset;
      }
    }

    a.articles__promo-block {
      &:hover {
        .articles__promo-img {
          transform: scale(1.05);
        }
      }
    }

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: right center;
      transition: transform 0.5s;
      display: none;

      @media only screen and (min-width: $screen-w--m) {
        display: block;
      }

      &--left {
        background-position: 410px center;
      }
    }

    &-head {
      position: relative;
      z-index: 1;
      padding-top: 45px;
      font-family: $font-family--varBold;
      font-size: 25px;
      max-width: 550px;

      @media only screen and (max-width: 767px) {
        max-width: 380px;
        padding-top: 35px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.04;
        letter-spacing: normal;
      }

      @media only screen and (min-width: $screen-w--s) {
        font-size: 40px;
        padding-top: 90px;
        margin-bottom: -5px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      font-size: $font-size--m;
      margin-top: 15px;
      max-width: 457px;

      @media screen and (max-width: 767px) {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        margin-right: 70px;
      }

      @media only screen and (min-width: $screen-w--s) {
        font-size: 20px;
        max-width: 457px;
      }

      @media only screen and (min-width: $screen-w--m) {
        padding: 0;
        width: 575px;
      }
    }

    &-text--wide {
      position: relative;
      z-index: 1;
      font-size: $font-size--m;
      margin-top: 15px;
      max-width: 457px;

      @media screen and (max-width: 767px) {
        max-width: 380px;
        margin-right: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
      }

      @media only screen and (min-width: $screen-w--s) {
        font-size: 20px;
        max-width: 589px;
      }

      @media only screen and (min-width: $screen-w--m) {
        padding: 0;
        width: 589px;
      }
    }

    &-text-container {
      text-align: left;

      &--align-r {
        @media only screen and (min-width: $screen-w--m) {
          float: right;
          margin-right: 168px;
        }
      }

      &--align-l {
        @media only screen and (min-width: $screen-w--m) {
          float: left;
        }
      }
    }

    &-label {
      margin-top: 10px;
      user-select: none;

      span {
        opacity: 0.99;
        border-radius: 3px;
        background-color: #7d5ce4;
        font-size: 12px;
        letter-spacing: 2.3px;
        text-transform: uppercase;
        color: #fff;
        padding: 2px 7px;
        display: inline-block;
      }
    }
  }
}
