.blured-items {
  z-index: 20;
  position: relative;
  width: 100%;
  bottom: 0;
  left: 0;
  background: inherit;
  margin: 40px 0 0;

  @media only screen and (min-width: $screen-w--s) {
    position: absolute;
    margin: 0;
    #blured-item-img--alfabank {
      margin-top: 6px;
    }
    #blured-item-img--akbars {
      max-height: 36px;
    }
    height: 105px;
  }

  @media only screen and (min-width: $screen-w--l) {
    height: 220px;
  }

  .container-items {
    display: grid;
    margin: 0 auto 20px auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 35px 35px;
  }

  .blured-item--dom-rf {
    align-self: center;
    justify-self: center;
  }

  .blured-item--rosbankdom {
    align-self: center;
    justify-self: center;
  }

  .blured-item--tkb {
    align-self: center;
    justify-self: center;
  }

  .blured-item--unicredit {
    align-self: center;
    justify-self: center;
    opacity: 0.8;
  }

  .blured-item--alfabank {
    align-self: center;
    justify-self: center;
  }

  .blured-item--absolute {
    align-self: center;
    justify-self: center;
    opacity: 0.3;
  }

  .blured-item--bjf {
    align-self: center;
    justify-self: center;
  }

  .blured-item--ipoteka24 {
    align-self: center;
    justify-self: center;
  }

  .blured-item--sovkombank {
    align-self: center;
    justify-self: center;
  }

  .blured-item--uralsib {
    align-self: center;
    justify-self: center;
    opacity: 0.3;
  }

  .blured-item--zenit {
    align-self: center;
    justify-self: center;
    opacity: 0.3;
  }

  .blured-item--raif {
    align-self: center;
    justify-self: center;
    opacity: 0.2;
  }

  .blured-item--akbars {
    align-self: center;
    justify-self: center;
    opacity: 0.3;
  }

  .blured-item--mkb {
    align-self: center;
    justify-self: center;
    opacity: 0.3;
  }

  .blured-item--otkrytie {
    align-self: center;
    justify-self: auto;
    opacity: 0.3;
    padding-left: 5px;
  }

  .blured-item--lb {
    align-self: center;
    justify-self: center;
    opacity: 0.4;
  }

  .blured-item--lb > img {
    height: 35px;
    max-height: 35px !important;
  }

  .blured-item--psb {
    align-self: center;
    justify-self: center;
    opacity: 0.2;
  }

  .blured-item-psb > img {
    height: 20px;
    max-height: 20px !important;
  }

  .blured-item--primsocbank {
    align-self: center;
    justify-self: center;
    opacity: 0.3;
  }

  .blured-item--primsocbank > img {
    height: 45px;
    max-height: 45px !important;
  }

  .blured-item--mtsbank {
    align-self: center;
    justify-self: center;
    opacity: 0.2;
  }

  .blured-item--rshb > img {
    height: 35px;
    max-height: 35px !important;
  }

  .blured-item--rshb {
    align-self: center;
    justify-self: center;
    opacity: 0.2;
  }

  .blured-item--sberbank {
    align-self: center;
    justify-self: center;
    opacity: 0.6;
  }

  .blured-item--vtb {
    align-self: center;
    justify-self: center;
    opacity: 0.2;
  }


  .item {
    filter: url(../../img/content/filter.svg#grayscale);
    filter: gray;
    -webkit-filter: grayscale(1);
  }

  @media only screen and (max-width: $screen-w--xs) {
    .container-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
      gap: 45px 35px;
    }
  }

  @media only screen and (min-width: $screen-w--s) {
    .container-items {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 35px 35px;
      margin-top: -30px;
    }
    .blured-item--dom-rf {
      align-self: center;
      justify-self: center;
    }
    .blured-item--rosbankdom {
      align-self: center;
      justify-self: center;
    }
    .blured-item--tkb {
      align-self: center;
      justify-self: center;
    }
    .blured-item--unicredit {
      align-self: center;
      justify-self: center;
    }
    .blured-item--alfabank {
      align-self: center;
      justify-self: center;
    }
    .blured-item--absolute {
      align-self: center;
      justify-self: center;
    }
    .blured-item--bjf {
      align-self: center;
      justify-self: center;
    }
    .blured-item--ipoteka24 {
      align-self: center;
      justify-self: center;
    }
    .blured-item--sovkombank {
      align-self: center;
      justify-self: center;
    }
    .blured-item--akbars {
      align-self: center;
      justify-self: center;
    }
  }

  @media only screen and (min-width: $screen-w--l) {
    .container-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 30px 30px;
      margin-top: 0;
    }
    .blured-item--dom-rf {
      align-self: center;
      justify-self: center;
    }
    .blured-item--rosbankdom {
      align-self: center;
      justify-self: center;
      max-width: 100px;
    }
    .blured-item--tkb {
      align-self: center;
      justify-self: center;
    }
    .blured-item--unicredit {
      align-self: center;
      justify-self: center;
      max-width: 100px;
    }
    .blured-item--alfabank {
      align-self: center;
      justify-self: center;
      max-width: 80px;
    }
    .blured-item--absolute {
      align-self: center;
      justify-self: center;
    }
    .blured-item--bjf {
      align-self: center;
      justify-self: center;
    }
    .blured-item--ipoteka24 {
      align-self: center;
      justify-self: center;
    }
    .blured-item--sovkombank {
      align-self: center;
      justify-self: center;
      max-width: 120px;
    }
    .blured-item--uralsib {
      align-self: center;
      justify-self: center;
      max-width: 130px;
    }
    .blured-item--raif {
      align-self: center;
      justify-self: center;
      max-width: 100px;
    }

    .blured-item--akbars {
      align-self: center;
      justify-self: center;
      max-width: 130px;
    }

    .wrapper-items {
      margin: 0 auto;
      width: $screen-w--l;
    }
  }

  .wrapper-items {
    margin: 0 auto;
    max-width: 970px;

    img {
      max-height: 25px;
    }
  }
}
