.carousel {
    &__wrapper {
        width: 142.5%;
        overflow: hidden;

        @media only screen and (min-width: $screen-w--s) {
            width: 129.947917%;
        }
    }

    &__block {
        overflow: unset !important;
        margin: 0 -8px;
        width: 286px;

        @media only screen and (min-width: $screen-w--s) {
            // width: 336px;
            width: 672px;
            margin: 0 -5px;
        }

        @media only screen and (min-width: $screen-w--l) {
            width: 1008px;
        }
    }

    &__item {
        background-color: $color-white;
        min-height: 320px;
        margin: 0 8px;

        @media only screen and (min-width: $screen-w--s) {
            min-height: 435px;
            margin: 0 5px;
        }
    }

    &__nav {
        display: none;
        position: absolute;
        top: 12px;
        right: 30px;
        margin: 0 -14px;
        user-select: none;

        @media only screen and (min-width: $screen-w--s) {
            display: block;
        }

        @media only screen and (min-width: $screen-w--l) {
            right: auto;
            left: 805px;
        }
    }

    &__nav-prev,
    &__nav-next {
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
        color: $color-black;
        padding: 10px;
        cursor: pointer;
        margin: 0 4px;
    }

    &__nav-prev {
        transform: rotate(90deg);
    }

    &__nav-next {
        transform: rotate(-90deg);
    }
}

.carousel-plate {
    position: relative;

    @media only screen and (min-width: $screen-w--l) {
        margin-left: calc(50% - 535px);
    }

    &__block {
        & > div {
            & > div {
                // width: auto !important;

                // @media only screen and (min-width: $screen-w--l) {
                //     margin-left: calc(50% - 535px);
                // }
            }
        }
    }

    &__item {
        width: 230px;
        height: 460px;

        & > div, & > a {
            height: 230px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px;

            img {
                max-width: 100%;
            }
        }
    }

    &__nav {
        user-select: none;
        position: absolute;
        right: 35px;
        top: -76px;
    }
}