.block-sending {
  display: none;

  &.active {
    display: block;
  }

  &__name {
    font-family: $font-family--varBold;
    font-size: 25px;
    margin-bottom: 12px;
    line-height: $line-height--sm;

    @media only screen and (min-width: $screen-w--l) {
      font-size: $font-size--xxl;
      margin-bottom: 22px;
    }
  }

  &__info {
    margin-bottom: 20px;

    @media only screen and (min-width: $screen-w--l) {
      font-size: $font-size--l;
      line-height: $line-height--xl;
      margin-bottom: 60px;
    }

    &-footer {
      margin-bottom: 20px;
    }
  }

  &__sub-info {
    font-size: $font-size--sl;
    color: $color-white;

    @media only screen and (min-width: $screen-w--s) {
      font-size: $font-size--m;
    }

    span {
      opacity: 0.6;
    }
  }

  &__agreement {
    font-size: 18px;
    margin-top: 20px;
    display: inline-block;
    cursor: pointer;

    a {
      color: #016eff;
    }

    [type="checkbox"]
    {
      vertical-align:middle;
      width: 16px;
      height: 16px;
      color: #7d5ce4;
      accent-color: #7d5ce4;
      margin-right: 3px;
    }

  }
}

.block-sending--white {
  .block-sending__sub-info {
    color: $color-grey--v6;

    span {
      opacity: 1;
    }

    a {
      color: $color-blue--v2;
    }
  }
}

.block-sending--popup {
  .block-sending__sub-info {
    color: $color-grey--v6;

    span {
      opacity: 1;
    }

    a {
      color: $color-blue--v2;
    }
  }
}

.block-success {
  position: relative;
  padding-top: 17px;
  text-align: center;
  display: none;

  @media only screen and (min-width: $screen-w--s) {
    padding-top: 0;
    text-align: left;
  }

  @media only screen and (min-width: $screen-w--mx) {

  }

  &.active {
    display: block;
  }

  &__icon {
    background-color: $color-yellow--v2;
    width: 77px;
    height: 77px;
    border-radius: 100%;
    margin: 0 auto 23px;
    text-align: center;

    @media only screen and (min-width: $screen-w--s) {
      width: 68px;
      height: 68px;
      margin: 0;
      position: absolute;
      left: 0;
      top: 0;
    }

    i {
      display: block;
      font-size: 23px;
      color: $color-white;
      padding-top: 27px;

      @media only screen and (min-width: $screen-w--s) {
        font-size: 20px;
        padding-top: 24px;
      }
    }
  }

  &__name {
    font-family: $font-family--varBold;
    color: $color-white;
    margin-bottom: 8px;

    @media only screen and (min-width: $screen-w--s) {
      padding-left: 89px;
      font-size: $font-size--l;
      margin-bottom: 10px;
    }
  }

  &__info {
    font-size: $font-size--sl;
    color: $color-white;

    @media only screen and (min-width: $screen-w--s) {
      padding-left: 89px;
      max-width: 579px;
      font-size: $font-size--m;
    }

    a {
      color: $color-blue--v2;
    }
  }
}

.block-success--v2 {
  text-align: center;
  padding-top: 83px;

  &.partner-modal-success {
    padding-top: 0px !important;
  }

  @media only screen and (min-width: $screen-w--s) {
    padding-top: 85px;
  }

  .block-success__icon {
    position: static;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: $screen-w--s) {
      width: 102px;
      height: 102px;
      margin-bottom: 20px;
    }

    i {
      @media only screen and (min-width: $screen-w--s) {
        font-size: 30px;
        padding-top: 35px;
      }
    }
  }

  .block-success__name {
    padding-left: 0;
    color: $color-grey--v4;

    @media only screen and (min-width: $screen-w--s) {
      font-size: $font-size--xxl;
      line-height: $line-height--sm;
      margin-bottom: 22px;
    }
  }

  .block-success__info {
    color: $color-grey--v4;

    @media only screen and (min-width: $screen-w--s) {
      font-size: $font-size--l;
      line-height: $line-height--xl;
      padding-left: 0;
      max-width: 100%;
    }
  }
}

.block-success--v3 {
  .block-success__name,
  .block-success__info {
    color: $color-grey--v4;
  }
}
