
.lds-css{
    display: flex;
    justify-content: center;
    height: 100%;
    margin-top: 20%;

    @-webkit-keyframes lds-rolling {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    .lds-rolling {
        position: relative;
    }

    .lds-rolling div,
    .lds-rolling div:after {
        position: absolute;
        width: 100px;
        height: 100px;
        border: 5px solid #3550ed;
        border-top-color: transparent;
        border-radius: 50%;
    }

    .lds-rolling div {
        -webkit-animation: lds-rolling 1s linear infinite;
        animation: lds-rolling 1s linear infinite;
        top: 100px;
        left: 100px;
    }

    .lds-rolling div:after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .lds-rolling {
        width: 200px !important;
        height: 200px !important;
        -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }
}
