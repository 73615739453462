.chat-block {
    padding: 50px 0;
    background: $color-grey--v2;

    @media only screen and (min-width: $screen-w--s) {
        padding: 90px 0;
    }

    @media only screen and (min-width: $screen-w--m) {
        padding: 140px 0;
    }

    &__container {
        @media only screen and (min-width: $screen-w--m) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__l {
        flex-shrink: 0;

        @media only screen and (min-width: $screen-w--m) {
            max-width: 400px;
            margin-right: 50px;
        }
    }

    &__r {

    }

    &__head {
        margin-bottom: 15px;
        color: $color-black;

        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 30px;
        }
    }

    &__desc {
        font-size: 20px;
        line-height: 30px;

        @media only screen and (max-width: $screen-w--xs) {
            font-size: 16px;
            line-height: 1.25;
            margin-bottom: 50px;
        }

        @media only screen and (min-width: $screen-w--xs) {
            margin-bottom: 20px;
        }
    }
}

.chat {
    padding: 56px 43px 56px 17px;

    @media only screen and (min-width: $screen-w--s) {
        padding: 56px 93px 56px 56px;
    }

    @media only screen and (min-width: $screen-w--l) {
        max-width: 640px;
    }

    &--main {
        max-width: 100%;
        padding: 0;

        .chat-questions {
            margin-bottom: 33px;

            @media only screen and (max-width: $screen-w--xs) {
                margin-bottom: 20px;
            }

            p {
                padding: 0 0 0 60px;

                @media only screen and (min-width: $screen-w--xs) {
                    padding: 0 0 0 80px;
                }

                &::before {
                    bottom: -12px;
                    width: 33px;
                    height: 33px;
                    font-size: 17px;

                    @media only screen and (min-width: $screen-w--xs) {
                        bottom: -22px;
                        width: 63px;
                        height: 63px;
                        font-size: 38px;
                        background-size: 30px
                    }
                }
            }

            span {
                // background: $color-purple--v1;
                // color: $color-white;
                font-family: $font-family--varBold;
                font-size: 35px;
                border-radius: 45px;
                padding: 11px 19px;
                max-width: 225px;

                @media only screen and (max-width: $screen-w--xs) {
                    font-size: 16.5px;
                }

                @media only screen and (min-width: $screen-w--xs) {
                    max-width: 410px;
                    padding: 20px 35px;
                }

                &::before {
                    border-right: 35px solid #f1f1f1;
                    background: #f1f1f1;
                    bottom: 8px;
                    left: -12px;
                }

                &::after {
                    background: $color-grey--v2;
                    bottom: 3px;
                    left: -30px;
                }
            }
        }

        .chat-answers {
            padding: 0;

            .btn {
                font-size: 14px;

                @media only screen and (min-width: $screen-w--s) {
                    padding: 12px 18px;
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
    }

    &-row {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        margin-bottom: 22px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-questions {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0 0 5px 0;
            padding: 0 0 0 65px;
            position: relative;

            &:last-child {
                margin-bottom: 0;

                &::before {
                    display: flex;
                }

                & span::before, & span::after {
                    display: block;
                }
            }

            &::before {
                content: '';
                background: url(../img/content/square.svg) $color-purple--v1 no-repeat center center;
                background-size: 20px;
                display: none;
                align-items: center;
                justify-content: center;
                color: #fff;
                position: absolute;
                left: 0;
                bottom: -23px;
                width: 44px;
                height: 44px;
                border-radius: 100%;
                z-index: 2;
            }

            @media only screen and (max-width: $screen-w--xs) {
                &::before {
                    background-size: 15px;
                }
            }
        }

        span {
            background: #f1f1f1;
            padding: 13px 14px;
            border-radius: 25px;
            display: inline-block;
            width: auto;
            position: relative;
            z-index: 1;

            &::before {
                display: none;
                content: '';
                position: absolute;
                bottom: -4px;
                z-index: -1;
                left: -16px;
                height: 30px;
                border-right: 35px solid #f1f1f1;
                background: #f1f1f1;
                border-bottom-right-radius: 80px 40px;
            }

            &::after {
                display: none;
                content: '';
                position: absolute;
                bottom: -9px;
                left: -30px;
                z-index: -1;
                width: 60px;
                height: 33px;
                background: #fff;
                border-bottom-right-radius: 30px 50px;
                -webkit-transform: translate(-30px, -2px);
                transform: translate(-30px, -2px);
            }

        }
    }

    &-answers {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        padding-left: 40px;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
        }

        &.chosed {
            &::after {
                display: block;
            }

            .btn--grey {
                background: rgba(13, 26, 69, 0.1);
            }
        }

        @media only screen and (min-width: $screen-w--s) {
        }

        .btn {
            width: auto;
            margin: 0 0 5px 5px;
            font-family: $font-family--varRegular;
            padding: 10px 15px;
            font-size: $font-size--m;
            white-space: normal;
            text-align: center;
        }
    }
}
