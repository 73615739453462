.nav {
    &[data-block="nav"] {
        display: none;
        position: fixed;
        left: 39px;
        top: 264px;
        z-index: $zindex-fixed;

        @media only screen and (min-width: $screen-w--l) {
            display: block;
        }

        &.static {
            position: absolute;
        }

        a {
            position: relative;
            color: $color-black;
            opacity: 0.5;
            font-size: $font-size--s;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            white-space: nowrap;
            display: block;
            margin-bottom: 18px;
            line-height: $line-height--s;

            &::before {
                content: "";
                position: absolute;
                top: 1px;
                left: -19px;
                width: 8px;
                height: 8px;
                border: 2px solid $color-black;
                opacity: 0.3;
                border-radius: 100%;
            }

            &[data-change-color="light"] {
                color: $color-white;
                opacity: 0.5;

                &::before {
                    border-color: $color-white;
                }
            }

            &.active {
                opacity: 1;
                font-family: $font-family--varBold;
                font-size: $font-size--sm;

                &[data-change-color="light"] {
                    &::before {
                        background-color: $color-white;
                    }
                }

                &::before {
                    background-color: $color-black;
                    opacity: 1;
                    // top: 2px;
                }
            }
        }
    }
    &[data-header="news"] {
        &.gray {
            background-color: #f5f5f5;

            @media only screen and (max-width: $screen-w--m) {
                .news-list {
                    .title-block {
                        a {
                            bottom: 100px;
                        }
                    }
                }
            }
        }

        .nav_block {
            padding-top: 110px;
            border-bottom: solid 2px #1e1f21;

            @media only screen and (max-width: $screen-w--m) {
                overflow: hidden;
            }

            .category-list {
                display: flex;
                flex-flow: wrap;
                line-height: 1.5;
                margin: 0px 0 30px 0;
                @media only screen and (max-width: $screen-w--m) {
                    flex-flow: nowrap;
                    overflow: scroll;
                }

                .category-list-item {
                    list-style-type: none;
                    margin-right: 20px;
                    white-space: nowrap;

                    a {
                        color: $color-grey--v4;
                        font-size: $font-size--m;
                        display: block;

                        &.active {
                            color: #016eff;
                        }

                        &:hover {
                            color: #016eff;
                        }
                    }

                    &:last-child {
                        margin-right: 0px;
                        margin-left: auto;
                    }
                }
            }

            .search-block {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 17px;

                input {
                    width: 80%;
                    height: 50px;
                    border-radius: 4px;
                    background-color: #f0f0f0;
                    margin-right: 10px;
                    font-size: 18px;
                    padding: 10px;
                    border: 0px;
                }

                button {
                    font-size: 18px;
                    color: $color-white;
                    width: 147px;
                    height: 50px;
                    border-radius: 4px;
                    background-color: #016eff;
                    margin-right: 40px;
                    cursor: pointer;
                    border: 0px;

                    &:hover {
                        background-color: #87cefa;
                    }
                }

                a {
                    font-size: 25px;
                    color: $color-black;

                    &:hover {
                        color: #016eff;
                    }
                }
            }
        }

        .news-list {
            margin-top: 20px;
            margin-bottom: 30px;

            @media only screen and (max-width: $screen-w--m) {
                position: relative;
            }

            .recent-posts {
                display: flex;
                margin: 0 0 40px 0;

                @media only screen and (max-width: $screen-w--m) {
                    flex-direction: column;
                }

                .recent-posts-item {
                    list-style-type: none;
                    margin-right: 20px;
                    border-bottom: solid 1px $color-grey--v5;
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media only screen and (max-width: $screen-w--m) {
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    a {
                        color: $color-grey--v4;
                        font-size: $font-size--m;
                        font-family: $font-family--varBold;
                        display: block;
                        &:hover {
                            color: #016eff;
                        }
                    }

                    p {
                        margin: 10px 0;
                        color: #949494;
                        font-size: $font-size--sl;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }

                    @media only screen and (max-width: $screen-w--m) {
                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .links {
            color: #1e1f21;
            display: flex;
            align-items: flex-end;
            font-size: 20px;
            opacity: 0.5;
            padding-bottom: 50px;
            padding-top: 20px;

            @media only screen and (min-width: $screen-w--s) {
                padding-top: 0;
            }

            .icon-facebook-xs {
                margin-left: 20px;
                color: $color-grey--d3;
                font-size: 26px;

                &:hover {
                    color: #016eff;
                }
            }

            .icon-vk {
                margin-left: 20px;
                color: $color-grey--d3;
                font-size: 32px;

                &:hover {
                    color: #016eff;
                }
            }
        }
    }
}

// Safari mac os menu twitches when scroll

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .nav a.active {
            font-family: $font-family--varRegular;
        }
    }
}
