.agency {
    padding-top: 88px;
    padding-bottom: 88px;

    @media only screen and (min-width: $screen-w--s) {
        padding: 232px 0;
    }

    @media only screen and (min-width: $screen-w--mx) {
        height: 750px;
    }

    &__head {
        margin-top: 30px;
        margin-bottom: 10px;

        @media only screen and (min-width: $screen-w--s) {
            font-size: 60px;
            margin-top: 0;
        }

        @media only screen and (min-width: $screen-w--m) {
            font-size: 80px;
        }
    }
    &__info {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 20px;
        br {
            display: none;

            @media only screen and (min-width: $screen-w--s) {
                display: block;
            }
        }

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            line-height: inherit;
            margin-bottom: 40px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            margin-bottom: 50px;
        }
    }

    &__promo {
        text-align: center;

        @media only screen and (min-width: $screen-w--mx) {
            display: flex;
            flex-wrap: wrap;
        }

        a[href] {
            cursor: pointer !important;
        }

        a {
            cursor: default;
            position: relative;
            display: block;
            width: 100%;
            padding: 0 17px;
            height: 200px;

            @media only screen and (min-width: $screen-w--mx) {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                padding: 0 25px;
                height: 350px;
            }

            &:nth-child(1) {
                .agency__promo-img {
                    background-image: url("../img/content/promo-5.jpg");

                    @media only screen and (min-width: $screen-w--s) {
                        background-image: url("../img/content/promo-5-s.jpg");
                    }

                    @media only screen and (min-width: $screen-w--mx) {
                        background-image: url("../img/content/promo-5-l.jpg");
                    }
                }
                .agency__promo-head,
                .agency__promo-text {
                    color: $color-white;
                }
            }

            &:nth-child(2) {
                .agency__promo-img {
                    background-image: url("../img/content/promo-4.jpg");

                    @media only screen and (min-width: $screen-w--s) {
                        background-image: url("../img/content/promo-4-s.jpg");
                    }

                    @media only screen and (min-width: $screen-w--mx) {
                        background-image: url("../img/content/promo-4-l.jpg");
                    }
                }
            }

            &:nth-child(3) {
                .agency__promo-img {
                    background-image: url("../img/content/promo-6.jpg");

                    @media only screen and (min-width: $screen-w--s) {
                        background-image: url("../img/content/promo-6-s.jpg");
                    }

                    @media only screen and (min-width: $screen-w--mx) {
                        background-image: url("../img/content/promo-6-l.jpg");
                    }
                }

                .agency__promo-head,
                .agency__promo-text {
                    color: $color-white;
                }
            }
        }
    }

    &__promo-img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 100%;

        @media only screen and (min-width: $screen-w--mx) {
            background-position: 50% 50%;
        }
    }

    &__promo-head {
        position: relative;
        z-index: 1;
        padding-top: 25px;
        font-family: $font-family--varBold;
        line-height: $line-height--sm;
        margin-bottom: 7px;
        color: $color-grey--v4;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            padding-top: 40px;
            line-height: $line-height--sl;
            margin-bottom: 10px;
        }
    }

    &__promo-text {
        position: relative;
        z-index: 1;
        font-size: $font-size--sl;
        line-height: $line-height--sl;
        color: $color-grey--v4;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--m;
            line-height: $line-height--m;
            width: 500px;
            margin: 0 auto;
        }

        @media only screen and (min-width: $screen-w--mx) {
            width: auto;
        }
    }

    &__promo-badge {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    &__divider {
        opacity: 0.3;
        color: $color-black;
        margin: 0;
    }

    &__contact {
        padding-top: 75px;
        padding-bottom: 80px;

        &-head {
            font-family: $font-family--varBold;
            line-height: $line-height--sm;
            color: $color-grey--v4;
            margin-bottom: 5px;

            @media only screen and (min-width: $screen-w--s) {
                font-size: $font-size--l;
                line-height: $line-height--sl;
            }
        }

        &-text {
            font-size: $font-size--sl;
            line-height: $line-height--sl;
            color: $color-grey--v4;
            margin-bottom: 5px;

            @media only screen and (min-width: $screen-w--s) {
                font-size: $font-size--m;
                line-height: $line-height--m;
            }
        }

        a {
            color: $color-blue--v2;
            transition: color 0.15s ease-in-out;
            font-size: 25px;
            line-height: $line-height--sl;

            &:hover {
                color: $color-blue--v4;
            }
        }
    }

    &__background {
        position: absolute;
        right: 0;
        background-size: 100%;
        z-index: 0;
        display: none;
        @media only screen and (min-width: $screen-w--s) {
            display: block;
            //width: 227px;
            //height: 206px;
            right: 50px;
        }

        @media only screen and (min-width: $screen-w--m) {
            font-size: 14px;
            top: -80px;
            right: 0px;
            width: 770px;
            height: 475.2px;
        }
    }
    &__text-container {
        position: relative;
        z-index: 1;
    }
}

.agency--request {
    background-color: $color-white;
    padding-bottom: 0;
    padding-top: 0;

    @include clearfix();

    @media only screen and (min-width: $screen-w--s) {
        padding-bottom: 0;
        padding-top: 0;
    }

    @media only screen and (min-width: $screen-w--mx) {
        height: auto;
    }

    .agency__block {
        padding-bottom: 88px;
        padding-top: 88px;

        @media only screen and (min-width: $screen-w--s) {
            padding-bottom: 170px;
            padding-top: 232px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            height: 650px;
        }
    }

    .agency__head {
        color: $color-grey--v4;
    }

    .agency__info {
        color: $color-grey--v4;
    }
}

.send-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  a, a:hover, a:visited {
    color: #2A79FF;
  }
}
