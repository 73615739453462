
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-newspaper-folded-svgrepo-com:before {
    content: "\e920";
  }
  .icon-odnoklassniki:before {
    content: "\e91f";
  }
  .icon-telegram:before {
    content: "\e91d";
  }
  .icon-vk:before {
    content: "\e91e";
  }
  .icon-new-fix-coziness:before {
    content: "\e916";
  }
  .icon-new-fix-floor:before {
    content: "\e917";
  }
  .icon-new-fix-light:before {
    content: "\e918";
  }
  .icon-new-fix-photo:before {
    content: "\e919";
  }
  .icon-new-fix-socket:before {
    content: "\e91a";
  }
  .icon-new-fix-surfaces:before {
    content: "\e91b";
  }
  .icon-new-private-cabinet:before {
    content: "\e91c";
  }
  .icon-mortgage-menu:before {
    content: "\e915";
  }
  .icon-mortgage:before {
    content: "\e904";
  }
  .icon-fix-coziness:before {
    content: "\e90e";
  }
  .icon-fix-floor:before {
    content: "\e90f";
  }
  .icon-fix-furniture:before {
    content: "\e910";
  }
  .icon-fix-light:before {
    content: "\e911";
  }
  .icon-fix-photo:before {
    content: "\e912";
  }
  .icon-fix-socket:before {
    content: "\e913";
  }
  .icon-fix-surfaces:before {
    content: "\e914";
  }
  .icon-car:before {
    content: "\e90d";
  }
  .icon-arrow:before {
    content: "\e90c";
  }
  .icon-leave-application:before {
    content: "\e909";
  }
  .icon-private-cabinet:before {
    content: "\e90a";
  }
  .icon-lease:before {
    content: "\e90b";
  }
  .icon-tick:before {
    content: "\e900";
  }
  .icon-tick-xl:before {
    content: "\e901";
  }
  .icon-new-building:before {
    content: "\e906";
  }
  .icon-about-company:before {
    content: "\e907";
  }
  .icon-agents:before {
    content: "\e908";
  }
  .icon-sale:before {
    content: "\e905";
  }
  .icon-facebook-xs:before {
    content: "\e903";
  }
  .icon-close:before {
    content: "\e902";
  }
  .icon-search:before {
    content: "\e986";
  }
  .icon-facebook:before {
    content: "\ea90";
  }
  .icon-whatsapp:before {
    content: "\ea93";
  }
