$c: ".collapse";

#{$c} {
    &__block {
        &.active {
            #{$c}__head {
                // background-color: rgba(255, 0, 0, 0.1);
            }

            #{$c}__head-icon {
                transform: rotate(180deg);
            }

            #{$c}__body {
                display: block;
            }
        }
    }

    &__head {
        position: relative;
        // padding: 15px 20px;
        cursor: pointer;
        font-size: $font-size--sl;
        line-height: $line-height--sl;
        // border-top: 1px solid $color-grey--v9;
    }

    &__head-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        // left: 0;
        font-size: 6px;
        font-weight: bold;
    }

    &__body {
        display: none;
        overflow: hidden;
    }

    &__content {
        padding: 15px 0;
    }
}

#{$c}--calculator {
    #{$c}__head {
        padding: 15px 20px;
        border-top: 1px solid $color-grey--v9;
    }

    #{$c}__head-icon {
        left: 0;
    }
}

#{$c}--preparation {
    #{$c}__block {
        border-bottom: 1px solid $color-grey--v9;
    }

    #{$c}__head {
        padding: 14px 28px 14px 35px;
        color: $color-blue--v2;

        @media only screen and (min-width: $screen-w--s) {
            font-size: 20px;
            padding: 20px 50px;
        }
    }

    #{$c}__head-icon {
        right: 0;
        color: $color-grey--v4;

        @media only screen and (min-width: $screen-w--s) {
            font-size: 9px;
        }
    }

    #{$c}__head-icon-pict {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        text-align: center;

        @media only screen and (min-width: $screen-w--s) {
            width: 36px;
        }
    }

    #{$c}__content {
        padding: 0 28px 15px 35px;
        font-size: $font-size--sl;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--l;
            padding: 0 50px 25px;
        }
    }

    .icon-fix-light {
        font-size: 24px;

        @media only screen and (min-width: $screen-w--s) {
            width: 34px;
        }
    }

    .icon-fix-furniture {
        font-size: 20px;

        @media only screen and (min-width: $screen-w--s) {
            width: 30px;
        }
    }

    .icon-fix-socket {
        font-size: 21px;

        @media only screen and (min-width: $screen-w--s) {
            width: 31px;
        }
    }

    .icon-new-fix-surfaces {
        font-size: 20px;

        @media only screen and (min-width: $screen-w--s) {
            width: 28px;
        }
    }

    .icon-fix-photo {
        font-size: 20px;

        @media only screen and (min-width: $screen-w--s) {
            width: 30px;
        }
    }

    .icon-fix-coziness {
        font-size: 23px;

        @media only screen and (min-width: $screen-w--s) {
            width: 33px;
        }
    }

    .icon-fix-floor {
        font-size: 25px;

        @media only screen and (min-width: $screen-w--s) {
            width: 36px;
        }
    }
}
