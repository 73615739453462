.form-controls {
    @media only screen and (min-width: $screen-w--s) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__control {
        position: relative;
        margin-bottom: 10px;

        @media only screen and (min-width: $screen-w--s) {
            margin-bottom: 0;

            &:nth-child(1) {
                flex: 0 0 41.690962%;
                max-width: 41.690962%;
            }

            &:nth-child(2) {
                flex: 0 0 28.425656%;
                max-width: 28.425656%;
            }

            &:nth-child(3) {
                flex: 0 0 28.425656%;
                max-width: 28.425656%;
            }
        }

        @media only screen and (min-width: $screen-w--mx) {
            &:nth-child(1) {
                flex: 0 0 55.555556%;
                max-width: 55.555556%;
            }

            &:nth-child(2) {
                flex: 0 0 21.666667%;
                max-width: 21.666667%;
            }

            &:nth-child(3) {
                flex: 0 0 21.666667%;
                max-width: 21.666667%;
            }
        }

        &.is-error {
            .form-controls__control-input {
                background-color: $color-pink--v1;
            }
        }
    }

    &__error {
        display: none;
        color: $color-red--v1;
        margin: 4px 0 0 2px;
        user-select: none;

        &.is-error {
            display: block;
            float: left;
            margin: 5px 0 12px 0;
            font-size: 16px;
        }
    }

    &__control-input {
        width: 100%;
        background-color: $color-white;
        border-radius: 4px;
        border: none;
        padding: 13px 12px;
        line-height: $line-height--sm;
        font-size: $font-size--m;
        color: $color-grey--v4;

        &::placeholder {
            font-family: "sf_ui_displayregular";
        }

        @media only screen and (min-width: $screen-w--s) {
            padding: 15px 13px;
            font-size: $font-size--ml;
        }
    }
}

.form-controls--v2 {
    display: block;

    .form-controls__control {
        @media only screen and (min-width: $screen-w--s) {
            max-width: 100%;
            margin-bottom: 10px;
        }

        @media only screen and (min-width: $screen-w--l) {
            margin-bottom: 15px;
        }
    }

    .form-controls__control-input {
        background-color: $color-grey--v11;
    }
}

.form-controls--v3 {
    .form-controls__control-input {
        background-color: $color-grey--v11;
    }
}

.control-checkbox {
    input {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        width: 0.1px;
        height: 0.1px;
    }

    input:checked ~ .control-checkbox__block {
        &::before {
            background-color: $color-blue--v2;
        }
    }

    &__block {
        position: relative;
        cursor: pointer;
        user-select: none;
        padding-left: 28px;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--m;
            line-height: $line-height--sl;
            padding-top: 2px;
            padding-left: 34px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            padding-left: 28px;
            padding-top: 0;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 100%;
        }

        &::before {
            width: 16px;
            height: 16px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 2px;

            @media only screen and (min-width: $screen-w--s) {
                width: 22px;
                height: 22px;
            }

            @media only screen and (min-width: $screen-w--mx) {
                width: 16px;
                height: 16px;
            }
        }

        &::after {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e900";
            font-size: 6px;
            color: $color-white;
            top: 4px;
            left: 4px;

            @media only screen and (min-width: $screen-w--s) {
                font-size: 8px;
                top: 7px;
                left: 6px;
            }

            @media only screen and (min-width: $screen-w--mx) {
                font-size: 6px;
                top: 4px;
                left: 4px;
            }
        }
    }
}

.control-radio {
    input {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        width: 0.1px;
        height: 0.1px;
    }

    input:checked ~ .control-radio__block {
        &::before {
            background-color: $color-blue--v2;
        }
    }

    &__block {
        position: relative;
        cursor: pointer;
        user-select: none;
        padding-left: 28px;
        white-space: nowrap;

        @media only screen and (min-width: $screen-w--s) {
            font-size: $font-size--m;
            line-height: $line-height--sl;
            padding-left: 30px;
        }

        @media only screen and (min-width: $screen-w--mx) {
            padding-left: 28px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            border-radius: 100%;
            transition: all 250ms;
        }

        &::before {
            width: 18px;
            height: 18px;
            background: transparent;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 100%;
        }

        &::after {
            content: '';
            background: #fff;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            top: 7px;
            left: 6px;
        }
    }
}
