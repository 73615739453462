.header {
  position: absolute;
  z-index: 1020;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  padding: 25px 25px 0;

  &__menu {
    margin-left: auto;
    display: none;

    @media only screen and (min-width: $screen-w--mx) {
      display: block;
      padding-left: 39px;
    }

    &-item {
      margin-right: 17px;
      margin-bottom: 5px;
      margin-top: 5px;
      color: rgba($color-black--v2, 0.6);
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      display: inline-block;

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  &[data-header='dark'] {
    background: $color-white;
    color: $color-black;
    height: 70px;

    a {
      color: $color-black;
    }

    p {
      color: #78797a;
    }

    button {
      background: $color-grey--v13;
      color: $color-white;
    }

    & .header__list {
      li.active {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: $color-black;
          left: 0;
          bottom: -26px;
        }
      }
    }

    @media only screen and (max-width: $screen-w--m) {
      .hamburger__icons,
      .hamburger__icons:after,
      .hamburger__icons:before {
        background-color: black !important;
      }
    }
  }

  // @media only screen and (max-width: $screen-w--s) {
  //     position: fixed;
  //     height: 50px !important;
  // }

  &__logo {
    display: block;
    background: url('../img/logo.svg') no-repeat;
    width: 187px;
    height: 22px;
    background-size: cover;
    flex-shrink: 0;

    @media only screen and (min-width: $screen-w--s) {
      top: 20px;
      width: 240px;
      height: 27px;
      left: 40px;
    }
    @media only screen and (min-width: $screen-w--m) {
      left: 93px;
    }
  }

  &__phone {
    z-index: 1;
    font-size: 20px;
    font-weight: 500;
    margin-left: auto;
    white-space: nowrap;

    @media only screen and (min-width: $screen-w--mx) {
      font-size: 14px;
      margin: 0;
    }

    &:after {
      content: '';
      background: url(../img/icons/icon-phone.svg) no-repeat center;
      width: 25px;
      height: 25px;
      display: block;
      right: 17px;

      @media only screen and (min-width: $screen-w--s) {
        display: none;
      }
    }
    span {
      display: none;
      @media only screen and (min-width: $screen-w--s) {
        display: block;
      }
    }
  }

  // &__list {
  //     position: absolute;
  //     top: 22px;
  //     left: 145px;
  //     display: none;

  //     @media only screen and (min-width: $screen-w--m) {
  //         display: block;
  //     }

  //     li {
  //         display: inline-block;
  //         margin-left: 18px;

  //         &:first-child {
  //             margin-left: 0;
  //         }
  //     }

  //     a {
  //         color: $color-white;
  //         font-size: $font-size--sm;
  //         line-height: $line-height--s;
  //     }
  // }

  &__contact {
    position: absolute;
    top: 20px;
    right: 17px;
    display: none;

    @media only screen and (min-width: $screen-w--m) {
      display: flex;
      align-items: center;
    }

    li {
      display: inline-block;
      margin-left: 18px;
      margin-right: 18px;
      text-align: right;
      p {
        color: $color-grey--v5;
        font-size: 14px;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: $color-white;
      font-size: $font-size--ml;
    }

    button {
      margin-top: -20px;
      background: $color-white;
      color: inherit;
      font-family: $font-family--var1;
      font-size: $font-size--sl;
    }
  }

  &[data-header='news'] {
    background: $color-grey--v15;
    color: $color-grey--v16;
    height: 70px !important;

    @media only screen and (max-width: $screen-w--s) {
      position: fixed;
      height: 50px !important;
    }

    a {
      color: $color-grey--v16;
    }

    button {
      margin-top: 0px;
      background: $color-white;
      color: $color-black;
    }

    & .hamburger__icons {
      background: #fff;
      &::before,
      &::after {
        background: #fff;
      }
    }

    & .header__list {
      li.active {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: $color-grey--v16;
          left: 0;
          bottom: -26px;
        }
      }
    }
  }
}

body.popup-open {
  .header {
    display: none;
  }
}

body.popup-open.popup-open-first {
  .header {
    display: flex;
  }
}
