.items-row {
    display: flex;
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 30px 0 0;

    &__item {
        margin: 0 20px 40px;
        display: flex;
        align-items: center;

        @media only screen and (min-width: $screen-w--m) {
            margin: 0 25px 60px 0;
          &--alfabank {
            margin-top: 18px;
          }
        }
      @media only screen and (max-width: $screen-w--m) {
        &--alfabank {
          margin-top: 20px;
          & img {
            max-height: 25px;
          }
        }
      }

        @media only screen and (max-width: $screen-w--s) {
            margin: 0 10px 30px 0;
          &--alfabank {
            margin-top: 15px;
            & img {
              max-height: 25px;
            }
          }
        }

        @media only screen and (max-width: $screen-w--xs) {
            margin: 0 10px 30px 0;
        }

        img {
            max-width: 90%;
        }
    }

  &--col-5 {
    grid-template-columns: repeat(5, 20%);
  }
}
