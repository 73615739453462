.Partner_X-Holding {

    .spotygo {
      width: 160px;
      height: 38px;
      margin-top: 5px;
    }

    &.violet {
        .Partner {
            &__submit {
                color: #ffffff;
                background-color: #7d5ce4;
            }
        }
    }

    >header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        >hr {
            width: 0;
            height: 51px;
            border: solid 1px #595959;
            display: inline-block;
            margin: 0 4%;
        }

        img {
            max-width: 42%;
            margin: 0;
        }
    }

    .Partner {
        &__IpotekaCenter {
            width: 254px;
            margin-top: -5px;
        }

        &__appeal {
            font-family: MuseoSansCyrl_900;
            font-size: 40px;
            line-height: 1.1;
            color: #333333;
            margin-top: .5em;
        }

        &__email, &__phone, &__region, &__name {
            display: flex;
            flex-wrap: wrap;
            font-family: MuseoSansCyrl_500;
            font-size: 18px;
            color: #333333;
            margin: 1em 0 0;
            width: 100%;
            position: relative;

            > .is-error {
                position: absolute;
                bottom: -2em;
            }
        }

        &__email, &__phone {
            width: 47%;
        }

        &__region {
            position: relative;

            &:after {
                display: block;
                content: '';
                position: absolute;
                right: .3em;
                background-image: url(/img/arrows.svg);
                bottom: 16px;
                width: 9px;
                height: 18px;
            }

            select {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;

                &::-ms-expand {
                    display: none;
                }
            }
        }

        &__control {
            height: 50px;
            border-radius: 4px;
            background-color: #f0f0f0;
            border: none;
            margin: .5em 0 0;
            width: 100%;
            font-family: MuseoSansCyrl_500;
            font-size: 18px;
            color: #333333;
            padding: .5em 1em
        }

        &__form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 1em 0 0;
        }

        &__submit {
            height: 50px;
            border-radius: 4px;
            background-color: #ffd450;
            border: none;
            width: 100%;
            margin-top: 2em;
            font-family: MuseoSansCyrl_500;
            font-size: 18px;
            color: #333333;
            cursor: pointer;
        }

        &__agreement {
            font-family: MuseoSansCyrl_500;
            font-size: 18px;
            color: #333333;
            text-align: left;
            margin-top: 1.5em;

            a {
                color: #016eff;
            }
        }
    }
}
