$p: ".popup";

#{$p} {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 1030;
    overflow: hidden;
    outline: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    background-color: $color-white;
    -webkit-overflow-scrolling: touch;

    @media only screen and (min-width: $screen-w--l) {
        width: auto;
    }

    &.active {
        transform: translateX(0);
    }

    &__main {
        position: relative;
        z-index: 1030;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;

        @include clearfix();
    }

    &__content {
        min-height: 100%;
        height: 100%;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 19px;
        z-index: 1050;
        cursor: pointer;

        @media only screen and (min-width: $screen-w--s) {
            top: 23px;
            right: 24px;
        }

        i {
            font-size: 18px;
            color: $color-black--v2;

            @media only screen and (min-width: $screen-w--s) {
                font-size: 25px;
            }
        }
    }
}

#{$p}--2 {
    z-index: 2030;
}

.popup-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1020;
    background-color: $color-black;
    opacity: 0.5;

    &.active {
        display: block;
    }
}

.popup-overlay--2 {
    z-index: 2020;
}

.popup-open {
    .popup {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.scroll-locked {
    height: 100vh;
    overflow: hidden;
}
