/* gulp.spritesmith v6.2.1 */

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/

@mixin sprite-width($sprite) {
    width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
    height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);

    background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);

    background-image: url("#{$sprite-image}");
}

@mixin sprite($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite);
}

/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ("icon-home", $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/

@mixin sprite-background-size($sprite) {
    $sprite-total-width: nth($sprite, 7);
    $sprite-total-height: nth($sprite, 8);

    background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
    $normal-sprite: nth($retina-group, 2);
    $retina-sprite: nth($retina-group, 3);

    @include sprite($normal-sprite);

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @include sprite-image($retina-sprite);
        @include sprite-background-size($normal-sprite);
    }
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/

@mixin sprites($sprites) {
    @each $sprite in $sprites {
        $sprite-name: nth($sprite, 10);

        .#{$sprite-name} {
            @include sprite($sprite);
        }
    }
}

/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/

@mixin retina-sprites($retina-groups) {
    @each $retina-group in $retina-groups {
        $sprite-name: nth($retina-group, 1);

        .#{$sprite-name} {
            @include retina-sprite($retina-group);
        }
    }
}
